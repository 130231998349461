import {Delivery} from "../domain/Delivery";
import {generateSheet} from "../export";
import {utils, writeFile} from "xlsx";
import {DateTimeFormat, Instant, Now} from "@variocube/app-ui";
import {useLocalization} from "../i18n";

class ExportDeliveriesService {

    private static filename(tenantId: string, now: Instant): string {
        const formatted = new DateTimeFormat(undefined, {
            dateStyle: "short",
            timeStyle: "short"
        }).format(now);
        return `deliveries-${tenantId}-${formatted}.xlsx`;
    }

    async export(tenantId: string, sheetName: string, deliveries: Delivery[], language:string): Promise<void> {

		const dateFormat:string =  language ==='de' ?  'DD.MM.YY HH:MM' : 'MM/DD/YY HH:MM AM/PM';
        const sheet = generateSheet(deliveries, [
            {name: "Delivery-ID", value: e => e.delivery.deliveryId, width: 30},
            {name: "State", value: e => e.delivery.state, width: 15},
            {name: "Foreign-ID", value: e => e.metadata ? e.metadata.foreignId : '', width: 30},
            {name: "Order-ID", value: e => e.order.orderId, width: 30},
            {name: "Order-Description", value: e => e.order.description, width: 30},
            {name: "Recipient Names", value: e => e.recipients ? e.recipients.map((r) => r.recipientName).join(', ') : '', width: 30},
			{name: "Recipient Departments", value: e => e.recipients ? e.recipients.filter((r) => r.department).map((r) => r.department).join(', ') : '', width: 30},
			{name: "Recipient Building", value: e => e.recipients ? e.recipients.filter((r) => r.building).map((r) => r.building).join(', ') : '', width: 30},
            {name: "Location", value: e => e.storage ? e.storage.destinationDescription : '', width: 30},
            {name: "Box", value: e => e.delivery.boxNumber, width: 10},
            {name: "Created At", value: e => this.toDate(e.delivery.createdAt), width: 18, format: dateFormat, type:'d'},
            {name: "Stored At", value: e => this.toDate(e.delivery.storedAt|| ''), width: 18, format: dateFormat,  type:'d'},
            {name: "Picked-Up At", value: e => this.toDate(e.delivery.pickedUpAt || ''), width: 18, format: dateFormat,  type:'d'},
            {name: "Sender", value: e => e.delivery ? e.delivery.sender : '', width: 30},
            {name: "Parcel-ID", value: e => e.delivery ? e.delivery.parcelId : '', width: 30},
            {name: "Carrier", value: e => e.delivery ? e.delivery.carrier : '', width: 15},
            {name: "Weight", value: e => e.delivery ? e.delivery.weight : '', width: 5},
        ]);

        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, sheet, sheetName);
        writeFile(workbook, ExportDeliveriesService.filename(tenantId, Now.instant()));
    }

	private toDate(dateString: string) {
		let result;
		try {
		  result = new Date(Instant.from(dateString).epochMilliseconds);
		}
		catch (e) {
		}
		return result;
	}
}

export const exportDeliveriesService = new ExportDeliveriesService();
