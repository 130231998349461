import * as React from "react";
import {Delete} from "@mui/icons-material";
import {Grid, IconButton, Typography} from "@mui/material";
import {createElement, useState} from "react";
import {Recipient} from "../../domain/Delivery";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {isEmail} from "../../tools";
import {RecipientSearchV2} from "../recipients/RecipientSearchV2";
import {SimpleTextInput} from "../SimpleTextInput";
import {RecipientSummary} from "../../domain/Recipients";

interface DispatchRecipientsListProps {
	label: string;
	description: string;
	list: Recipient[];
	onChange: (list: Recipient[]) => void;
}

export function DispatchRecipientsList(props: DispatchRecipientsListProps) {
	const {label, description, list, onChange} = props;
	const {t} = useLocalization();

	let [recipient,setRecipient] = useState<Recipient>()
	let [selectedIndex, setSelectedIndex] = useState<number |  undefined>()

	const handleNameChanged = (index: number, value: string) => {
		const copy = {
			...list[index],
		};
		copy.recipientName = value;
		setRecipient(copy);
		onChange([...list.slice(0, index), copy, ...list.slice(index + 1)]);
	};

	const handleEmailChanged = (index: number, value: string) => {
		const copy = {
			...list[index],
		};
		copy.email = value;
		setRecipient(copy);
		onChange([...list.slice(0, index), copy, ...list.slice(index + 1)]);
	};

	const handleAdd = () => {
		onChange([...list, {recipientName: "", email: ""}]);
	};

	const handleDelete = (index: number) => {
		const copy = [...list];
		copy.splice(index, 1);
		onChange(copy);
		setSelectedIndex(undefined);
	};

	let recipients =  list.map<RecipientSummary>( x => ( { recipientName:  x.recipientName, email: x.email} ));


	return (
		<Grid container spacing={gs}>
			<Grid item xs={12}>
				<Grid container spacing={gs}>
					<Grid item style={{flexGrow: 1}}>
						<Typography variant="h6">{label}</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body2">{description}</Typography>
			</Grid>
			<Grid item xs={12}>
				<RecipientSearchV2 label="Empfänger suchen" recipients={true} departments={true} multiple={true}
                                   values={recipients} onChange={onChange} />
			</Grid>
			{selectedIndex != undefined  &&
				<Grid item xs={12}>
					<Grid container spacing={gs} >
						<Grid item xs={12}>
							<Typography variant="body2">{t("recipients.actions.edit")}</Typography>
						</Grid>
						<Grid item style={{flexGrow: 1}}>
							<Grid container spacing={gs}>
								<Grid item sm={6} xs={12}>
									<SimpleTextInput
										size="small"
										label={t("recipients.name")}
										value={recipient?.recipientName}
										onChange={(value) =>  selectedIndex != undefined && handleNameChanged(selectedIndex, value)}
										error={!Boolean(recipient?.recipientName)}
									/>
								</Grid>
								<Grid item sm={6} xs={12}>
									<SimpleTextInput
										size="small"
										label={t("recipients.email")}
										value={recipient?.email}
										onChange={(value) => selectedIndex != undefined && handleEmailChanged(selectedIndex, value)}
										error={!isEmail(recipient?.email || "")}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<IconButton onClick={() => selectedIndex != undefined && handleDelete(selectedIndex)}>
								<Delete />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
			}
		</Grid>
	);
}
