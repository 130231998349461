import * as _ from 'lodash';


export interface IReturnDeliveryConfig {
    enabled: boolean,
    returnRequiresAuthorization: boolean,
    authorizationProviderHint?: string, 
    parameter: ReturnDeliveryParameter[],
}


export class ReturnDeliveryParameter {
    constructor(public nameId: string, public enabled: boolean = true, public mandatory: boolean, public verify: boolean) {
    }
}

export class ReturnDeliveryConfig {
    public static defaultConfig: IReturnDeliveryConfig =
        {
           	enabled: false,
            returnRequiresAuthorization: false,
            parameter: [
                new ReturnDeliveryParameter('returns.deliveryNoteId',true,true, false),
                new ReturnDeliveryParameter('returns.reasonForReturn',true,true, false),
                new ReturnDeliveryParameter('returns.email',true,false, false),
                new ReturnDeliveryParameter('returns.phone',true,false, false),
                new ReturnDeliveryParameter('returns.customerId',true,false, false),
                new ReturnDeliveryParameter('returns.positionNumbers',true,false, false),
                new ReturnDeliveryParameter('returns.additionalInfo',true,false, false),

            ],
        }

    public static merge(json?: string): IReturnDeliveryConfig {
        let result = JSON.parse(JSON.stringify(ReturnDeliveryConfig.defaultConfig));
        if (json) try {
            let parsed = JSON.parse(json);
            _.merge(result, parsed);
        } catch (ex) {   // failsafe!
            console.log('cannot parse ReturnConfig settings, using defaults:' + json);
        }
        return result;
    }
}

