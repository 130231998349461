import {ExpandMore} from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Divider,
	Grid,
	MenuItem,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import {debounce} from "lodash";
import {createElement, useCallback, useContext, useEffect, useState} from "react";
import {useAsync} from "react-async-hook";
import {DeliveriesProvider} from "../../../domain/DeliveriesProvider";
import {DeliveryCondition} from "../../../domain/Delivery";
import {OcrResponse} from "../../../domain/Ocr";
import {useLocalization} from "../../../i18n";
import {gs} from "../../../theme";
import {LHelpButton} from "../../LHelpButton";
import {TextField, useStorage} from "@variocube/app-ui";
import {useTenant} from "../../TenantContextProvider";
import {DeliveryTags} from "../DeliveryTags";
import {OcrInput} from "../OcrInput";
import {WizardStepContext} from "./contexts";
import {DeliveryDetailsData, LabelInfo} from "./types";

const StorageKey = "delivery-wizard-details-expanded";

interface StepDetailsProps {
	defaultDetails?: DeliveryDetailsData;
	onChange: (data: DeliveryDetailsData) => void;
	ocr?: OcrResponse;
	labelInfo?: LabelInfo;
}

export function StepDetails({defaultDetails, onChange, ocr, labelInfo}: StepDetailsProps) {
	const {t, e} = useLocalization();
	const tenant = useTenant();

	const {result: learnedTags} = useAsync(() => DeliveriesProvider.computeDeliveryLearnedTags(tenant.centerId), [
		tenant.centerId,
	]);

	const [expanded, setExpanded] = useStorage(StorageKey, false);

	useEffect(() => {
		const data = {
			condition: (defaultDetails?.condition ?? labelInfo?.condition) ?? condition,
			parcelId: (defaultDetails?.parcelId ?? labelInfo?.parcelId) ?? parcelId,
			weight: (defaultDetails?.weight ?? labelInfo?.weight) ?? weight,
			storageTimeMax: (defaultDetails?.storageTimeMax ?? labelInfo?.storageTimeMax) ?? storageTimeMax,
			sender: (defaultDetails?.sender ?? labelInfo?.sender) ?? sender,
			carrier: (defaultDetails?.carrier ?? labelInfo?.carrier) ?? carrier,
			description: (defaultDetails?.description ?? labelInfo?.description) ?? description,
			deliveryNoteId: (defaultDetails?.deliveryNoteId ?? labelInfo?.deliveryNoteId) ?? deliveryNoteId,
			orderId: (defaultDetails?.orderId ?? labelInfo?.orderId) ?? orderId,
			foreignId: (defaultDetails?.foreignId ?? labelInfo?.foreignId) ?? foreignId,
			callbackUrl: (defaultDetails?.callbackUrl ?? labelInfo?.callbackUrl) ?? callbackUrl,
			tags: (defaultDetails?.tags ?? labelInfo?.tags) ?? tags,
		};
		setCondition(data.condition);
		setParcelId(data.parcelId);
		setWeight(data.weight);
		setStorageTimeMax(data.storageTimeMax);
		setSender(data.sender);
		setCarrier(data.carrier);

		setDescription(data.description);
		setDeliveryNoteId(data.deliveryNoteId);
		setOrderId(data.orderId);
		setForeignId(data.foreignId);
		setCallbackUrl(data.callbackUrl);
		setTags(data.tags);

		onChange(data);
	}, [labelInfo]);

	useEffect(() => {
		lookupTopCarriers("");
		lookupTopSenders("");
	}, []);

	const [parcelId, setParcelId] = useState("");
	const [condition, setCondition] = useState(DeliveryCondition.Intact);
	const [weight, setWeight] = useState("");
	const [storageTimeMax, setStorageTimeMax] = useState(tenant.maxStorageTime);
	const [sender, setSender] = useState("");
	const [carrier, setCarrier] = useState("");
	const [topSenders, setTopSenders] = useState<string[]>([]);
	const [topCarriers, setTopCarriers] = useState<string[]>([]);

	const [description, setDescription] = useState("");
	const [deliveryNoteId, setDeliveryNoteId] = useState("");
	const [orderId, setOrderId] = useState("");
	const [foreignId, setForeignId] = useState("");
	const [callbackUrl, setCallbackUrl] = useState("");
	const [tags, setTags] = useState<string[]>([]);


	const lookupTopSenders = useCallback(
		debounce(async (search: string) => {
			try {
				setTopSenders(await DeliveriesProvider.computeDeliveryTopSender(tenant.centerId, search));
			} catch (err) {
				console.error("Failed to lookup top senders", err);
			}
		}, 200),
		[tenant.centerId],
	);

	const lookupTopCarriers = useCallback(
		debounce(async (search: string) => {
			try {
				setTopCarriers(await DeliveriesProvider.computeDeliveryTopCarrier(tenant.centerId, search));
			} catch (err) {
				console.error("Failed to lookup top senders", err);
			}
		}, 200),
		[tenant.centerId],
	);

	function handleDetailsChange(key: keyof DeliveryDetailsData, value: any) {
		const data: DeliveryDetailsData = {
			condition,
			storageTimeMax,
			parcelId,
			sender,
			carrier,
			weight,
			description,
			deliveryNoteId,
			orderId,
			foreignId,
			callbackUrl,
			tags,
		};
		switch (key) {
			case "parcelId":
				data.parcelId = value;
				setParcelId(value.substring(0,99));
				break;
			case "condition":
				console.log("condition changed", value);
				data.condition = value;
				setCondition(value);
				break;
			case "weight":
				data.weight = value.substring(0, 3);
				setWeight(value.substring(0, 3));
				break;
			case "storageTimeMax":
				data.storageTimeMax = value.substring(0, 3);
				setStorageTimeMax(value.substring(0, 3));
				break;
			case "sender":
				data.sender = value.substring(0, 99);
				setSender(value.substring(0, 99));
				lookupTopSenders(value);
				break;
			case "carrier":
				data.carrier = value.substring(0, 99);
				setCarrier(value.substring(0, 99));
				lookupTopCarriers(value);
				break;
			case "description":
				data.description = value.substring(0, 999);
				setDescription(value.substring(0, 999));
				break;
			case 'deliveryNoteId':
				data.deliveryNoteId = value.substring(0,255);
				setDeliveryNoteId(value.substring(0,255));
				break;
			case "orderId":
				data.orderId = value.substring(0, 255);
				setOrderId(value.substring(0, 255));
				break;
			case "foreignId":
				data.foreignId = value.substring(0, 255);
				setForeignId(value.substring(0, 255));
				break;
			case "callbackUrl":
				data.callbackUrl = value.substring(0, 255);
				setCallbackUrl(value.substring(0, 255));
				break;
			case "tags":
				data.tags = value;
				setTags(value);
				break;
		}

		onChange(data);
	}

	const {formReset} = useContext(WizardStepContext);

	useEffect(() => {
		if (formReset) {
			resetForm();
		}
	}, [formReset]);

	function resetForm() {
		const data = {
			condition: DeliveryCondition.Intact,
			parcelId: "",
			weight: "",
			storageTimeMax: tenant.maxStorageTime,
			sender: "",
			carrier: "",
			description: "",
			deliveryNoteId: "",
			orderId: "",
			foreignId: "",
			callbackUrl: "",
			tags: [],
		};
		setCondition(data.condition);
		setParcelId(data.parcelId);
		setWeight(data.weight);
		setStorageTimeMax(data.storageTimeMax);
		setSender(data.sender);
		setCarrier(data.carrier);

		setDescription(data.description);
		setDeliveryNoteId(data.deliveryNoteId);
		setOrderId(data.orderId);
		setForeignId(data.foreignId);
		setCallbackUrl(data.callbackUrl);
		setTags(data.tags);

		onChange(data);
		console.log("Step Details data reset.");
	}

	return (
		<Stack direction="column" spacing={3}>
			<Grid container spacing={3} maxWidth="md">
				<Grid
					item
					xs={12}
					md={8}
					sm={6}
				>
					<OcrInput
						label={t("deliveries.parcelId")}
						ocr={ocr}
						value={parcelId}
						onChange={v => handleDetailsChange("parcelId", v)}
						defaultFocused
						captureEnter
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={4}
					sm={6}
				>
					<Select
						fullWidth
						label={t("deliveries.condition")}
						value={condition}
						onChange={ev => handleDetailsChange("condition", ev.target.value)}
					>
						{Object.values(DeliveryCondition).map(c => (
							<MenuItem key={c} value={c}>{e("deliveries.conditions", c)}</MenuItem>
						))}
					</Select>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
				>
					<OcrInput
						label={t("deliveries.weight")}
						numeric
						ocr={ocr}
						value={weight}
						onChange={v => handleDetailsChange("weight", v)}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
				>
					<OcrInput
						label={t("deliveries.storage.storageTimeMax")}
						numeric
						ocr={ocr}
						value={storageTimeMax}
						onChange={v => handleDetailsChange("storageTimeMax", v)}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
				>
					<OcrInput
						label={t("deliveries.sender")}
						ocr={ocr}
						value={sender}
						onChange={v => handleDetailsChange("sender", v)}
						suggestions={topSenders.map(v => ({label: v, value: v}))}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
				>
					<OcrInput
						label={t("deliveries.carrier")}
						ocr={ocr}
						value={carrier}
						onChange={v => handleDetailsChange("carrier", v)}
						suggestions={topCarriers.map(v => ({label: v, value: v}))}
					/>
				</Grid>

				<Grid item xs={12}>
					<Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
						<AccordionSummary expandIcon={<ExpandMore />}>
							<Grid container spacing={gs}>
								<Grid item>
									<Box paddingTop={0.75}>
										<Typography variant="body2" color="textSecondary">
											{t("deliveries.create.deliveryDetails.optional.noMore")}
										</Typography>
									</Box>
								</Grid>
								<Grid item>
									<LHelpButton helpPage="Delivery_Create" helpAnchor="More_Fields" language="" />
								</Grid>
							</Grid>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Divider />
								</Grid>

								<Grid item xs={12}>
									<TextField
										fullWidth
										label={t("deliveries.order.description")}
										value={description}
										onChange={v => handleDetailsChange("description", v)}
									/>
								</Grid>

								<Grid
									item
									xs={12}
									sm={6}
								>
									<TextField
										fullWidth
										label={t("deliveries.order.deliveryNoteId")}
										value={deliveryNoteId}
										onChange={v => handleDetailsChange("deliveryNoteId", v)}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
								>
									<TextField
										fullWidth
										label={t("deliveries.order.orderId")}
										value={orderId}
										onChange={v => handleDetailsChange("orderId", v)}
									/>
								</Grid>

								<Grid item xs={12}>
									<Divider />
								</Grid>

								<Grid
									item
									xs={12}
									sm={4}
								>
									<TextField
										fullWidth
										label={t("deliveries.thirdParty.foreignId")}
										value={foreignId}
										onChange={v => handleDetailsChange("foreignId", v)}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={8}
								>
									<TextField
										fullWidth
										label={t("deliveries.thirdParty.callbackUrl")}
										value={callbackUrl}
										onChange={v => handleDetailsChange("callbackUrl", v)}
										type="url"
									/>
								</Grid>

								<Grid item xs={12}>
									<DeliveryTags
										label={t("deliveries.tags")}
										placeholder={t("deliveries.addTag")}
										tags={tags}
										options={learnedTags}
										onChange={v => handleDetailsChange("tags", v)}
									/>
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>
				</Grid>
			</Grid>
		</Stack>
	);
}
