import {Box, Button, Checkbox, FormControlLabel, Grid, Paper, TableCell, TableRow, Typography} from "@mui/material";
import {
	BreadcrumbItem,
	Breadcrumbs,
	ColumnType,
	ContentTable,
	Page,
	PagingSettings,
	TemporalFormat,
	tryParsePlainDate,
} from "@variocube/app-ui";
import {ContainerLayout} from "@variocube/app-ui";
import {NotFound} from "@variocube/app-ui/esm/layout/NotFound";
import {createElement, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {Link} from "react-router-dom";
import {DeliveriesProvider} from "../../domain/DeliveriesProvider";
import {Delivery, Recipient} from "../../domain/Delivery";
import {OutOfOffice} from "../../domain/OutOfOffice";
import {OutOfOfficeProvider} from "../../domain/OutOfOfficeProvider";
import {RecipientsProvider} from "../../domain/RecipientsProvider";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {BooleanDisplay} from "../BooleanDisplay";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";
import {ConfirmDialog} from "../ConfirmDialog";
import {CubeNameDisplay} from "../CubeNameDisplay";
import {DateComponent} from "../DateComponent";
import {DeliveryStateDisplay} from "../deliveries/DeliveryStateDisplay";
import {DeliveryTagsDisplay} from "../deliveries/DeliveryTagsDisplay";
import {DeliveryTypeDisplay} from "../deliveries/DeliveryTypeDisplay";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {LabeledData} from "../LabeledData";
import {Loading} from "../Loading";
import {deliveryPaging} from "../pagings";
import {useTenant, useTenantId, useTenantUserRole} from "../TenantContextProvider";
import {deliveryColumns} from "../uis";
import {OutOfOfficeDialog} from "./OutOfOfficeDialog";
import {OutOfOfficeView} from "./OutOfOfficeView";

function initialPaging() {
	const paging = deliveryPaging.getSettings();
	if (!paging.sort) {
		paging.sort = "delivery.createdAt";
		paging.direction = "desc";
		deliveryPaging.updateSettings(paging);
	}
	return paging;
}

export function RecipientView() {
	const {isAdmin, isSuperUser} = useTenantUserRole();
	const [recipient, setRecipient] = useState<Recipient>();
	const [page, setPage] = useState<Page<Delivery>>();
	const [columns, setColumns] = useState<ColumnType>();
	const [pageable, setPageable] = useState<PagingSettings<keyof typeof baseColumns>>(
		initialPaging() as PagingSettings<keyof typeof baseColumns>,
	);
	const {recipientId} = useParams<"recipientId">();
	const tenant = useTenant();
	const [outOfOfficeDialogOpen, setOutOfOfficeDialogOpen] = useState(false);
	const [outOfOffice, setOutOfOffice] = useState<OutOfOffice>();
	const [redirectedSuccessDialogOpen, setRedirectedSuccessDialogOpen] = useState(false);
	const [redirectMessage, setRedirectMessage] = useState<string>("");
	const [recipientNotFound, setRecipientNotFound] = useState<boolean>(false);

	const {t} = useLocalization();

	const baseColumns: ColumnType = useMemo<ColumnType>(() => ({
		"delivery.state": {show: true, name: t("deliveries.deliveryState")},
		"delivery.deliveryType": {show: false, name: t("deliveries.deliveryType")},
		"delivery.tags": {show: true, name: t("deliveries.tags")},
		"delivery.sender": {show: false, name: t("deliveries.sender")},
		"order.description": {show: true, name: t("deliveries.singular")},
		"recipients": {show: true, name: t("deliveries.recipients.plural"), unsortable: true},
		"delivery.createdAt": {show: true, name: t("deliveries.createdAt")},
		"changeHistory.createdBy": {show: false, name: t("deliveries.createdBy")},
	}), [t]);

	useEffect(() => {
		let columns = deliveryColumns.get();
		if (columns == null) {
			deliveryColumns.set(baseColumns);
			columns = baseColumns;
		}
		/*	DC: column "pickupKey" doesn't exist?
	    if (tenant && tenant.hideRecipientPickupKey) {
			columns["pickupKey"].show = false;
		}*/
		setColumns(columns);
		getDeliveries();
	}, [tenant, recipient]);

	useEffect(() => {
		const fetchRecipient = async () => {
			if (recipientId) {
				const rec = await RecipientsProvider.get(tenant.centerId, recipientId);
				setRecipient(rec);
				getDeliveries();
			}
		};
		fetchRecipient().catch((e) => {
			console.error(e);
			setRecipientNotFound(true);
		});
	}, []);

	useEffect(() => {
		const r = async () => {
			if (recipient?.email) {
				let o: OutOfOffice = await OutOfOfficeProvider.get(tenant.centerId, recipient?.email);
				setOutOfOffice(o);
			}
		};
		r();
	}, [recipient?.email]);

	const getDeliveries = useCallback(async () => {
		let deliveryPage;
		if (tenant) {
			try {
				if (recipient?.email) {
					deliveryPage = await DeliveriesProvider.listRecipientDeliveries(
						tenant.centerId,
						recipient.email,
						deliveryPaging,
					);
				} else if (recipient?.phone) {
					deliveryPage = await DeliveriesProvider.listRecipientDeliveriesByPhone(
						tenant.centerId,
						recipient.phone,
						deliveryPaging,
					);
				}
				setPage(deliveryPage);
			} catch (err) {
				console.error("Failed to fetch recipient deliveries", err);
			}
		}
	}, [recipient?.email, recipient?.phone]);

	async function handlePagingChange(pageable: PagingSettings<any>) {
		deliveryPaging.updateSettings(pageable);
		setPageable(pageable);
		setPage(undefined);
		await getDeliveries();
	}

	const handleColumnsChange = (c: ColumnType) => {
		setColumns(c);
		deliveryColumns.set(c);
	};

	async function handleOutOfOfficeSave(val: OutOfOffice) {
		setOutOfOfficeDialogOpen(false);
		await OutOfOfficeProvider.put(tenant.centerId, recipient?.email || "", val);
		if (val.active && val.redirectExisting && recipient?.email) {
			let recipientsNew = await RecipientsProvider.getRecipientsByEmails(tenant.centerId, val.substituteEmail);
			let count = await OutOfOfficeProvider.redirect(tenant.centerId, recipient, recipientsNew);
			setRedirectMessage(t("recipients.shipmentsRedirected", {no: count}));
			setRedirectedSuccessDialogOpen(true);
		}
		setOutOfOffice(val);
	}
	const renderColumnCheckbox = (key: keyof typeof baseColumns) => (
		(columns && columns[key])
			? (
				<FormControlLabel
					key={"column-setting-" + key}
					label={columns[key].name}
					color="primary"
					control={
						<Checkbox
							checked={columns[key].show}
							onChange={e =>
								handleColumnsChange({...columns, [key]: {...columns[key], show: e.target.checked}})}
						/>
					}
				/>
			)
			: null
	);

	const filteredColumns = useMemo<ColumnType | undefined>(() => {
		if (columns) {
			const copy = {...columns};
			copy["recipients"].show = false;
			return copy;
		}
	}, [columns]);

	if (recipientNotFound) {
		return (
			<NotFound errorMsg={t("notFound.title")} backToHomeMsg={t("notFound.home")} pathMsg={t("notFound.info")} />
		);
	}

	if (recipient) {
		return (
			<ContainerLayout>
				<HelmetTitleWrapper pageTitle={recipient.recipientName} />
				<Grid container spacing={gs}>
					<Grid item xs={12}>
						<Breadcrumbs>
							<BreadcrumbRouterLink to={`/${tenant.centerId}/recipients`}>
								{t("recipients.plural")}
							</BreadcrumbRouterLink>
							<BreadcrumbItem>{recipient.recipientName}</BreadcrumbItem>
						</Breadcrumbs>
					</Grid>
					<Grid item flexGrow={1}>
						<Typography variant="h2">{recipient.recipientName}</Typography>
					</Grid>
					{(isAdmin || isSuperUser)
						&& (
							<Grid item>
								<Button
									onClick={() => setOutOfOfficeDialogOpen(true)}
								>
									{t("recipients.outOfOfficeAssistant.title")}
								</Button>
							</Grid>
						)}
					<OutOfOfficeDialog
						open={outOfOfficeDialogOpen}
						outOfOffice={outOfOffice}
						onCancel={() => setOutOfOfficeDialogOpen(false)}
						onSave={handleOutOfOfficeSave}
					/>
					<ConfirmDialog
						open={redirectedSuccessDialogOpen}
						title={""}
						message={redirectMessage}
						noRejectButton
						resolve={() => setRedirectedSuccessDialogOpen(false)}
					/>
					<Grid item>
						<Button
							component={Link}
							to={`/${tenant.centerId}/recipients/${recipientId}/edit`}
							disabled={recipient.source == "Import"}
						>
							{t("edit")}
						</Button>
					</Grid>
					<Grid item xs={12}>
						<RecipientDetails
							recipient={recipient}
							outOfOffice={outOfOffice}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h3">{t("recipients.deliveryDetails")}</Typography>
					</Grid>
					<Grid item xs={12}>
						{!page && <Loading />}
						{(page && filteredColumns && pageable)
							&& (
								<ContentTable
									pageable={pageable}
									page={page}
									columns={filteredColumns}
									onPageableChange={handlePagingChange}
									onColumnsChange={handleColumnsChange}
									renderTableBody={
										<Fragment>
											{page.content.map((delivery, i) => (
												<DeliveryRow key={i} delivery={delivery} columns={filteredColumns} />
											))}
										</Fragment>
									}
									renderColumnFilter={
										<Fragment>
											<Typography variant="overline">{t("delivery")}</Typography>
											<Box my={1} />
											{[
												"delivery.state",
												"delivery.deliveryType",
												"delivery.tags",
												"delivery.sender",
												"delivery.createdAt",
												"delivery.createdBy",
												"delivery.parcelId",
											].map(renderColumnCheckbox)}
											<Box my={3} />

											<Typography variant="overline">{t("deliveries.order.singular")}</Typography>
											<Box my={1} />
											{[
												"order.description",
											].map(renderColumnCheckbox)}
										</Fragment>
									}
								/>
							)}
					</Grid>
				</Grid>
			</ContainerLayout>
		);
	} else {
		return <Loading />;
	}
}

interface DeliveryRowProps {
	delivery: Delivery;
	columns: ColumnType;
}

function DeliveryRow(props: DeliveryRowProps) {
	const {delivery, columns} = props;
	const navigate = useNavigate();
	const tenantId = useTenantId();
	const isColumnActive = useCallback((name: string) => (columns[name] && columns[name].show), [columns]);

	const generateRecipientDisplay = (recipients: Recipient[]) => {
		if (!recipients || recipients.length == 0) return "";
		return recipients.map(r => r.recipientName).join(", ");
	};

	function handleClick(delivery: Delivery) {
		if (delivery.id) {
			navigate(`/${tenantId}/deliveries/${delivery.id}`);
		}
	}

	return (
		<TableRow hover onClick={() => handleClick(delivery)}>
			{isColumnActive("delivery.state") && (
				<TableCell>
					<DeliveryStateDisplay deliveryState={delivery.delivery.state} size="small" />
				</TableCell>
			)}
			{isColumnActive("delivery.deliveryType") && (
				<TableCell>
					<DeliveryTypeDisplay deliveryType={delivery.delivery.deliveryType} size="small" />
				</TableCell>
			)}
			{isColumnActive("delivery.tags") && (
				<TableCell>
					<DeliveryTagsDisplay tags={delivery.tags} size="small" maxTags={2} />
				</TableCell>
			)}
			{isColumnActive("delivery.parcelId") && <TableCell>{delivery.delivery.parcelId}</TableCell>}
			{isColumnActive("delivery.sender") && <TableCell>{delivery.delivery.sender}</TableCell>}
			{isColumnActive("order.description") && <TableCell>{delivery.order.description}</TableCell>}
			{isColumnActive("recipients") && <TableCell>{generateRecipientDisplay(delivery.recipients)}</TableCell>}
			{isColumnActive("delivery.createdAt") && (
				<TableCell>
					{delivery.changeHistory?.createdAt && <DateComponent date={delivery.changeHistory.createdAt} />}
				</TableCell>
			)}
			{isColumnActive("changeHistory.createdBy") && (
				<TableCell>
					{delivery.changeHistory?.createdBy}
				</TableCell>
			)}
		</TableRow>
	);
}

interface RecipientDetailsProps {
	recipient: Recipient;
	outOfOffice?: OutOfOffice;
}

function RecipientDetails({recipient, outOfOffice}: RecipientDetailsProps) {
	const {t} = useLocalization();
	const tenant = useTenant();

	return (
		<Paper>
			<Box p={gs}>
				<Grid container spacing={gs}>
					{recipient.salutation
						&& (
							<Grid item xs={4}>
								<LabeledData label={t("recipients.salutation")}>
									{recipient.salutation}
								</LabeledData>
							</Grid>
						)}
					<Grid item xs={4}>
						<LabeledData label={t("recipients.name")}>
							{recipient.recipientName}
						</LabeledData>
					</Grid>
					{recipient.email
						&& (
							<Grid item xs={4}>
								<LabeledData label={t("recipients.email")}>
									{recipient.email}
								</LabeledData>
							</Grid>
						)}
					{recipient.phone
						&& (
							<Grid item xs={4}>
								<LabeledData label={t("recipients.phone")}>
									{recipient.phone}
								</LabeledData>
							</Grid>
						)}
					{recipient.cubeId
						&& (
							<Grid item xs={4}>
								<LabeledData label={t("recipients.cubeId")}>
									{recipient.cubeId && <CubeNameDisplay cubeId={recipient.cubeId} />}
								</LabeledData>
							</Grid>
						)}
					{recipient.preferredCubeId
						&& (
							<Grid item xs={4}>
								<LabeledData label={t("recipients.preferredCubeId")}>
									{<CubeNameDisplay cubeId={recipient.preferredCubeId} />}
								</LabeledData>
							</Grid>
						)}
					{recipient.department
						&& (
							<Grid item xs={4}>
								<LabeledData label={t("recipients.department")}>
									{recipient.department}
								</LabeledData>
							</Grid>
						)}
					{recipient.building
						&& (
							<Grid item xs={4}>
								<LabeledData label={t("recipients.building")}>
									{recipient.building}
								</LabeledData>
							</Grid>
						)}
					{recipient.employeeNumber
						&& (
							<Grid item xs={4}>
								<LabeledData label={t("recipients.employeeNumber")}>
									{recipient.employeeNumber}
								</LabeledData>
							</Grid>
						)}
					{recipient.position
						&& (
							<Grid item xs={4}>
								<LabeledData label={t("recipients.position")}>
									{recipient.position}
								</LabeledData>
							</Grid>
						)}
					{tenant && tenant.recipientPickupKeyExposed && recipient.pickupKey
						&& (
							<Grid item xs={4}>
								<LabeledData label={t("recipients.pickupKey")}>
									{recipient.pickupKey}
								</LabeledData>
							</Grid>
						)}
					{tenant && tenant.recipientPickupKeyExposed && recipient.learnedPickupKey
						&& (
							<Grid item xs={4}>
								<LabeledData label="Learned Pickup Key">
									{recipient.learnedPickupKey}
								</LabeledData>
							</Grid>
						)}

					{(outOfOffice && outOfOffice.active)
						&& <OutOfOfficeView outOfOffice={outOfOffice}></OutOfOfficeView>}

					{recipient.deleteAfter && (
						<Grid item xs={4}>
							<LabeledData label={t("recipients.deleteAfter")}>
								<TemporalFormat value={tryParsePlainDate(recipient.deleteAfter)} />
							</LabeledData>
						</Grid>
					)}
					<Grid item xs={4}>
						<LabeledData label={t("recipients.receiveNotifications")}>
							<BooleanDisplay value={recipient.receiveNotifications || false} />
						</LabeledData>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
}
