import {MenuItem} from "@mui/material";
import {TextField} from "@variocube/app-ui";
import * as React from "react";
import {createElement, useCallback, useEffect, useState} from "react";
import {useAsync} from "react-async-hook";
import {Cube} from "../../domain/Cube";
import {CubesProvider} from "../../domain/CubesProvider";
import {useLocalization} from "../../i18n";
import {Loading} from "../Loading";
import {useTenantId, useTenantUser, useTenantUserRole} from "../TenantContextProvider";

interface CubeSelectInputProps {
	value?: Cube;
	onChange: (cube?: Cube) => void;
	autoSelect?: boolean;
	allowUndefined?: boolean;
	required?: boolean;
	label?: string;
	cubes?: Cube[];
	error?: boolean;
	helperText?: string;
}

export function CubeSelectInput(props: CubeSelectInputProps) {
	const {
		value,
		onChange,
		autoSelect,
		allowUndefined,
		required,
		label,
		cubes: pCubes,
		helperText
	} = props;
	const {t} = useLocalization();
	const [cubeId, setCubeId] = useState<string>("");
	const tenantId = useTenantId();
	const user = useTenantUser();
	const {isAdmin} = useTenantUserRole();

	const {result: cubes} =  useAsync(async () => {
		if (pCubes) {
			return pCubes;
		}
		return isAdmin
			? await CubesProvider.list(tenantId)
			: await CubesProvider.listSiteCubes(tenantId,user.siteId);
	}, [tenantId, pCubes]);

	useEffect(() => {
		setCubeId(value ? value.hostname : (allowUndefined ? "none" : ""));
	}, [value]);

	useEffect(() => {
		if (autoSelect && cubes && cubes.length == 1) {
			handleChange(cubes[0].hostname);
		}
	}, [cubes, autoSelect]);

	const handleChange = useCallback((cubeId: string) => {
		if (cubes && cubeId) {
			const cube = cubes.find((c) => c.hostname === cubeId);
			setCubeId(cubeId);
			if (cube) {
				onChange(cube);
			} else {
				onChange(undefined);
			}
		}
	}, [cubes]);

	if (cubes) {
		return (
			<div>
				<TextField
					select
					fullWidth
					required={required}
					variant="outlined"
					label={label ? label : t("cubes.select")}
					value={cubeId}
					onChange={handleChange}
					validate={v => required && !v ? t('required') : undefined}
				>
					{allowUndefined
						&& <MenuItem key="none" value="none">{t("none")}</MenuItem>}
					{cubes.map((cube) => <MenuItem key={cube.hostname} value={cube.hostname}>{cube.name}</MenuItem>)}
				</TextField>
			</div>
		);
	}
	return <Loading />;
}
