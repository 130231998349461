import {
	Button,
	Grid,
	LinearProgress,
	Paper,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import {createElement, Fragment, useCallback, useMemo, useState} from "react";
import {DeliveriesProvider} from "../../domain/DeliveriesProvider";
import {RecipientSummary} from "../../domain/Recipients";
import {TenantManualHandoverOptions} from "../../domain/TenantUser";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {SignatureCanvas} from "../inputs/SignatureCanvas";
import {SimpleCheckBox} from "../SimpleCheckBox";
import {SimpleTextInput} from "../SimpleTextInput";
import {RecipientSearchV2} from "../recipients/RecipientSearchV2";
import {isAnyEnum} from "../../tools";
import { Loading } from "../Loading";

interface ManualHandoverProps {
	tenantId: string;
	deliveryIds: string[];
	onCancel: () => void;
	onConfirm?: () => void;
	options: TenantManualHandoverOptions;
	orderDescription: string;
}

export function ManualHandover(props: ManualHandoverProps) {
	const {tenantId, deliveryIds, onCancel, onConfirm, options} = props;
	const {t} = useLocalization();

	const [recipient, setRecipient] = useState<RecipientSummary>();
	const [name, setName] = useState<string>("");
	const [signature, setSignature] = useState<string>("");
	const [notes, setNotes] = useState("");
	const [employeeId, setEmployeeId] = useState("");
	const [allowExternalRecipient, setAllowExternalRecipient] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const handleSignatureChange = useCallback((canvas: HTMLCanvasElement, empty: boolean) => {
		setSignature(empty ? "" : canvas.toDataURL("image/png"));
	}, []);

	async function handleConfirm() {
		setSubmitting(true);
		try {
			for (let id of deliveryIds) {
				await DeliveriesProvider.setManualHandover(tenantId, id, {
					handedOverTo: name || recipient?.recipientName,
					signature,
					notes,
					employeeId,
				});
			}
			onConfirm && onConfirm(); 
			// onClose(true);
		} catch (e) {
			console.error(e);
		}
		setSubmitting(false);
	}

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const requireName = options == TenantManualHandoverOptions.Name
		|| options == TenantManualHandoverOptions.NameAndSignature
		|| (options == TenantManualHandoverOptions.RecipientOrName && allowExternalRecipient)
		|| (options == TenantManualHandoverOptions.RecipientOrNameAndSignature && allowExternalRecipient);

	const requireRecipient = options == TenantManualHandoverOptions.Recipient
		|| options == TenantManualHandoverOptions.RecipientAndSignature
		|| (options == TenantManualHandoverOptions.RecipientOrName && !allowExternalRecipient)
		|| (options == TenantManualHandoverOptions.RecipientOrNameAndSignature && !allowExternalRecipient);

	const showSignature = options == TenantManualHandoverOptions.Signature
		|| options == TenantManualHandoverOptions.NameAndSignature
		|| options == TenantManualHandoverOptions.RecipientAndSignature
		|| options == TenantManualHandoverOptions.RecipientOrNameAndSignature
		|| options == TenantManualHandoverOptions.SignatureOrEmployeeNumber;

	const requireSignature = options == TenantManualHandoverOptions.Signature
		|| options == TenantManualHandoverOptions.NameAndSignature
		|| options == TenantManualHandoverOptions.RecipientAndSignature
		|| options == TenantManualHandoverOptions.RecipientOrNameAndSignature;

	const canConfirm = useMemo<boolean>(() => {
		if (requireName && !name) {
			return false;
		}
		if (requireRecipient && !recipient) {
			return false;
		}
		if (requireSignature && !signature) {
			return false;
		}
		if (options == TenantManualHandoverOptions.SignatureOrEmployeeNumber && !signature && !employeeId) {
			return false;
		}
		return true;
	}, [requireRecipient, recipient, requireName, name, requireSignature, employeeId, signature, options]);

	return (
		<Grid container spacing={gs}>
			{isAnyEnum(options, TenantManualHandoverOptions.Name, TenantManualHandoverOptions.RecipientOrName, TenantManualHandoverOptions.RecipientOrNameAndSignature) &&
				<Grid item xs={12}>
					<SimpleCheckBox
						disabled={submitting}
						checked={allowExternalRecipient}
						label={t("deliveries.manualHandover.externalRecipient")}
						onChange={setAllowExternalRecipient}
					/>
				</Grid>
			}
			{requireRecipient
				&& (
					<Grid item xs={12}>
						<RecipientSearchV2 label={t("deliveries.create.recipientStep.selection")} recipients={true} departments={true} multiple={false}
										   values={recipient ? [recipient] : []} onChange={(r) => {
											   if(r && r.length > 0) {
												   setRecipient(r[0]);
											   }
										   }} />
					</Grid>
				)}
			{requireName
				&& (
					<Grid item xs={12}>
						<SimpleTextInput
							disabled={submitting}
							label={t("deliveries.create.recipientStep.name")}
							value={name}
							onChange={setName}
						/>
					</Grid>
				)}
			{showSignature
				&& (
					<Fragment>
						<Grid item xs={12}>
							<Typography variant="body1">{t("deliveries.manualHandover.signature")}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Paper>
								<SignatureCanvas
									onSignatureChange={handleSignatureChange}
									height={!fullScreen ? "250px" : undefined}
								/>
							</Paper>
						</Grid>
					</Fragment>
				)}
			{isAnyEnum(options, TenantManualHandoverOptions.SignatureOrEmployeeNumber) &&
				<Grid item xs={12}>
					<SimpleTextInput
						disabled={submitting}
						label={t("deliveries.manualHandover.employeeId")}
						value={employeeId}
						onChange={setEmployeeId}
					/>
				</Grid>
			}
			<Grid item xs={12}>
				<SimpleTextInput
					disabled={submitting}
					label={t("deliveries.manualHandover.notes")}
					value={notes}
					onChange={setNotes}
					multiline
					rows={3}
				/>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={gs}>
					<Grid item style={{flexGrow: 1}}>
					</Grid>
					<Grid item>
						<Grid container spacing={gs}>
							<Grid item>
								<Button variant="outlined" onClick={() => onCancel()} disabled={submitting}>{t("cancel")}</Button>
							</Grid>
							<Grid item>
								<Button
									color="primary"
									variant="contained"
									onClick={handleConfirm}
									disabled={submitting || !canConfirm}
								>
									{t("confirm")}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{submitting && <Loading></Loading>}
			{submitting && <LinearProgress variant="indeterminate" />}
		</Grid>
	);
}
