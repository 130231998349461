import {createContext, MutableRefObject} from "react";

interface ICameraRecordingContext {
	recording: boolean;
	setRecording: (value: boolean) => void;
	analyzingData: string;
	setAnalyzingData: (value: string) => void;
	analyzeBtnRef: MutableRefObject<HTMLButtonElement|null>|null;
}

export const CameraRecordingContext = createContext<ICameraRecordingContext>({
	recording: false,
	setRecording: _ => {
		throw new Error("CameraRecordingContext not correctly initialized.");
	},
	analyzingData: "",
	setAnalyzingData: _ => {
		throw new Error("CameraRecordingContext not correctly initialized.");
	},
	analyzeBtnRef: null
});
