import {
	Avatar,
	Box,
	Chip,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import {ChipPropsColorOverrides} from "@mui/material/Chip/Chip";
import {OverridableStringUnion} from "@mui/types";
import {BoxFeatures, Instant, Now} from "@variocube/app-ui";
import * as React from "react";
import {createElement} from "react";
import {useNavigate} from "react-router";
import {Cube, CubeBox, Occupancy} from "../../domain/Cube";
import {useLocalization} from "../../i18n";
import {DateComponent} from "../DateComponent";
import {DeliveryLink} from "../deliveries/DeliveryLink";
import {Loading} from "../Loading";
import {useTenantId} from "../TenantContextProvider";
import {CubeBoxLockStateComponent} from "./CubeBoxLockStateComponent";

interface BoxesListProps {
	cube: Cube;
	boxes: CubeBox[];
}

export function BoxesList({cube, boxes}: BoxesListProps) {
	if (boxes) {
		return (
			<Paper>
				<BoxesTable cube={cube} boxes={boxes} />
			</Paper>
		);
	}
	return <Loading />;
}

function BoxesTable(props: BoxesListProps) {
	const {t} = useLocalization();
	const {cube, boxes} = props;
	const sorted = boxes.sort((a, b) => a.number - b.number);
	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell style={{width: "5%"}}>{t("cubes.boxes.singular")}</TableCell>
					<TableCell style={{width: "5%"}}>{t("cubes.boxes.boxType")}</TableCell>
					<TableCell style={{width: "15%"}}>{t("cubes.boxes.features")}</TableCell>
					<TableCell style={{width: "15%"}}>{t("cubes.boxes.lockState.label")}</TableCell>
					<TableCell>{t("cubes.boxes.occupancy")}</TableCell>
					<TableCell style={{width: "10%"}}>{t("deliveries.storedAt")}</TableCell>
					<TableCell style={{width: "10%"}}>{t("deliveries.allowPickupUntil")}</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{boxes.length == 0
					&& (
						<TableRow>
							<TableCell colSpan={3}>
								<Box p={6}>
									<Typography variant="h6" align="center">{t("cubes.boxes.noBoxes")}</Typography>
								</Box>
							</TableCell>
						</TableRow>
					)}
				{sorted.map((box) => <BoxesTableRow key={box.lock} cube={cube} box={box} />)}
			</TableBody>
		</Table>
	);
}

interface BoxesTableRowProps {
	cube: Cube;
	box: CubeBox;
}

function BoxesTableRow(props: BoxesTableRowProps) {
	const {t} = useLocalization();
	const navigate = useNavigate();
	const tenantId = useTenantId();
	const {cube, box} = props;

	return (
		<TableRow
			style={{backgroundColor: box.disabled ? "#efefef" : undefined, cursor: "pointer"}}
			hover
			onClick={() => navigate(`/${tenantId}/cubes/${cube.hostname}/boxes/${box.number}`)}
		>
			<TableCell style={{whiteSpace: "nowrap"}}>
				<Avatar>{box.number}</Avatar>
			</TableCell>
			<TableCell>
				<BoxTypes boxTypes={box.types} />
			</TableCell>
			<TableCell>
				<BoxFeatures
					accessible={box.features.includes("Accessible")}
					cooled={box.features.includes("Cooled")}
					dangerousGoods={box.features.includes("DangerousGoods")}
					charger={box.features.includes("Charger")}
					minimized={true}
					t={t}
				/>
			</TableCell>

			<TableCell>
				<CubeBoxLockStateComponent lockState={box.lockStatus} />
				{box.maintenanceRequiredAt
					&& <Chip key={box.number} label={t("cubes.boxes.maintenanceRequired")}></Chip>}
			</TableCell>
			<TableCell>
				<Tooltip title={<DeliveryOverDueTooltipTitle color={getPickupUntilDateColor(box)} />}>
					<BoxOccupancies occupancies={box.occupancies} color={getPickupUntilDateColor(box)} />
				</Tooltip>
			</TableCell>
			<TableCell>
				<DateComponent date={getStoredDate(box)} />
			</TableCell>
			<TableCell>
				<DateComponent date={getPickupUntilDate(box)} />
			</TableCell>
		</TableRow>
	);
}

function getStoredDate(box: CubeBox) {
	if (box && box.occupancies && box.occupancies.length == 1) {
		return box.occupancies[0].created;
	}
}

function getPickupUntilDate(box: CubeBox) {
	if (box && box.occupancies && box.occupancies.length == 1) {
		if (box.occupancies[0].deliveries?.length == 1) {
			return box.occupancies[0].deliveries[0].delivery.allowPickupUntil;
		}
	}
}

function getFirstDelivery(box: CubeBox) {
	if (box && box.occupancies && box.occupancies.length == 1) {
		if (box.occupancies[0].deliveries?.length == 1) {
			return box.occupancies[0].deliveries[0];
		}
	}
}

function getPickupUntilDateColor(box: CubeBox): OverridableStringUnion<
	"default" | "primary" | "secondary" | "error" | "info" | "success" | "warning",
	ChipPropsColorOverrides
> {
	try {
		const delivery = getFirstDelivery(box);
		if (delivery) {
			const allowPickupUntil = delivery?.delivery.allowPickupUntil;
			const allowPickupUntilAsDate = new Date(allowPickupUntil);
			const allowPickupUntilAsInstant = Instant.fromEpochMilliseconds(allowPickupUntilAsDate.getTime());
			const expireDate = getStoredDate(box)?.add({hours: delivery?.storage?.storageTimeMax});

			if (expireDate && Instant.compare(Now.instant(), expireDate as Instant) > 0) {
				return "error";
			}

			if (allowPickupUntil && Instant.compare(Now.instant(), allowPickupUntilAsInstant) > 0) {
				return "warning";
			}
		}
	} catch {
	}
	return "default";
}

interface BoxOccupanciesProps {
	occupancies?: Occupancy[];
	color?: OverridableStringUnion<
		"default" | "primary" | "secondary" | "error" | "info" | "success" | "warning",
		ChipPropsColorOverrides
	>;
}

function BoxOccupancies(props: BoxOccupanciesProps) {
	const {occupancies, color} = props;
	return (
		<React.Fragment>
			{occupancies?.map((occupancy) =>
				occupancy.deliveries?.map((delivery) => (
					<DeliveryLink key={occupancy.uuid} delivery={delivery} color={color} maxLen={40} />
				))
			)}
		</React.Fragment>
	);
}

interface BoxTypesProps {
	boxTypes: string[];
}

function BoxTypes(props: BoxTypesProps) {
	const {boxTypes} = props;
	return (
		<React.Fragment>
			{boxTypes.map((boxType) => <Chip key={boxType} label={boxType} />)}
		</React.Fragment>
	);
}

interface DeliveryOverDueTooltipTitleProps {
	color: OverridableStringUnion<
		"default" | "primary" | "secondary" | "error" | "info" | "success" | "warning",
		ChipPropsColorOverrides
	>;
}

function DeliveryOverDueTooltipTitle({color}: DeliveryOverDueTooltipTitleProps) {
	const {t} = useLocalization();

	switch (color) {
		case "warning":
			return <span>{t("cubes.boxes.occupancyOverdue.warning")}</span>;
		case "error":
			return <span>{t("cubes.boxes.occupancyOverdue.error")}</span>;
		default:
			return <span>{t("cubes.boxes.occupancyOverdue.default")}</span>;
	}
}
