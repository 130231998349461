import {OcrResponse} from "./Ocr";
import {DeliveryCondition} from "./Delivery";

type Details = {
	condition: DeliveryCondition;
	weight?: string;
	carrier?: string;
	parcelId?: string;
}

class DetectLabelInfo {

	constructor( public carrierName:  string , private carrierRegex: RegExp,  private parcelIdRegex: RegExp[] ) {
		console.log(carrierName + "?");
	}

    detect(ocrResponse: OcrResponse)  {
		let details: Details  = {condition: DeliveryCondition.Intact}

		let kg = this.detectKg(ocrResponse);
		if (kg)
			details.weight = kg;

		let carrier = this.testRegex(ocrResponse,this.carrierRegex)
		if (carrier) {
			details.carrier = this.carrierName;
			let parcelID = this.detectParcelId(ocrResponse)
			details.parcelId = parcelID;
			console.log("matched: " + carrier + " " + parcelID + " " + parcelID?.length);
			return details;
		}
        return details;
	}

	checkDigit(parcelId: string | undefined) : boolean {
		// override and implement testing of carrier specific check digit
		return true;
	}


	detectKg(ocrResponse: OcrResponse) {
		let exec = this.execRegex(ocrResponse,/([0-9]{1,2}[., ]?[0-9]{0,3}) ?[kK][Gg]/);
		if (exec)
			return exec[1];

	}
	detectParcelId(ocrResponse: OcrResponse ) : string | undefined {
		for (let regex of this.parcelIdRegex) {
			let parcelId = this.execRegex(ocrResponse, regex);
			if (parcelId) {
				return parcelId[0];
			}
		}
		return undefined;
	}

	testRegex(ocrResponse: OcrResponse, regex: RegExp) : string  | undefined{
		for (let block of ocrResponse.blocks) {
			let exec = regex.exec(block.line)
			if (regex.test(block.line)) {
				return block.line;
			}
		}
		return undefined;
	}

	execRegex(ocrResponse: OcrResponse, regex: RegExp) {
		for (let block of ocrResponse.blocks) {
			let exec = regex.exec(block.line)
			if (exec) {
				return exec ;
			}
		}
		return undefined;
	}
}


class UPSLabel extends DetectLabelInfo {
	constructor() {
		super("UPS", /UPS/,[/1([a-zA-Z0-9] ?){17}/])
    }
}

class DHLLabel extends DetectLabelInfo {
	constructor() {
		super("DHL", /DHL/,[
			/JJD[0-9]{13}/,
			/\(?00\)?[0-9]{18}/,
			/[0-9]{20}/,
			/[0-9]{12}/])
	}
}

class AmazonLabel extends DetectLabelInfo {
	constructor() {
		super("Amazon", /CYCLE/,[/(AT|DE)[0-9]{10}/]);
	}
}

class FedExLabel extends DetectLabelInfo {
	constructor() {
		super("FedEx", /FedEx/,[/[0-9]{4} [0-9]{4} [0-9]{4}/])
	}
}

class DPDLabel extends DetectLabelInfo {
	constructor() {
		super("DPD", /DPD/,[/[0-9]{14}/])
	}
}

class GLSLabel extends DetectLabelInfo {
	constructor() {
		super("GLS", /GLS/,[/[a-zA-Z0-9]{8}/])
	}
}

class HermesLabel extends DetectLabelInfo {
	constructor() {
		super("Hermes", /Hermes/,[/[a-zA-Z0-9]{20}/])
	}
}

let parcelLabels: DetectLabelInfo[] = [
	new FedExLabel(),
    new UPSLabel(),
	new DHLLabel(),
	new AmazonLabel(),

	new DPDLabel(),
	new GLSLabel(),
	new HermesLabel(),
	]


export function detectLabelInfo	(ocrResponse:  OcrResponse) : Details | undefined {

	for (let block of ocrResponse.blocks) {
		console.log( "ocrresponse: " + block.line)
	}

	let details: Details | undefined ;
	for (let label of parcelLabels) {
		details = label.detect(ocrResponse)
		// if (label.checkDigit(details.parcelId) == false)  to be implemented
		//	details.parcelId = undefined;

		if (details?.carrier)
			break;
	}
	return details;
}
