import {DeliveryCondition} from "../../../domain/Delivery";
import {RecipientSummary} from "../../../domain/Recipients";
import {AppBoxFeature, Cube} from "../../../domain/Cube";

export const PHOTO_INBOX_PARAM = 'photoInbox';

export interface LabelInfo {
	condition: DeliveryCondition;
	sender?: string;
	carrier?: string;
	parcelId?: string;
	weight?: string;
	storageTimeMax?: number;
	foreignId?: string;
	callbackUrl?: string;
	orderId?: string;
	deliveryNoteId?: string;
	description?: string;
	tags?: string[];
}

export interface RecipientDetailsData {
	manualHandoverRequired: boolean;
	notifyRecipients: boolean;
	draft: boolean;
	recipient?: RecipientSummary;
}

export interface DeliveryDetailsData {
	condition: DeliveryCondition;
	storageTimeMax: number;
	parcelId?: string;
	weight?: string;
	sender?: string;
	carrier?: string;

	// Optional
	description?: string;
	deliveryNoteId?: string;
	orderId?: string;
	foreignId?: string;
	callbackUrl?: string;
	tags?: string[];
}

export interface BoxReservation {
	boxTypes: string[];
	boxFeature?: AppBoxFeature;
}
