import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import {Button, MobileStepper} from "@mui/material";
import {createElement, forwardRef, Fragment, useContext, useMemo} from "react";
import {useHotkey} from "../../../hotkeys";
import {useLocalization} from "../../../i18n";
import {CameraRecordingContext} from "../CameraRecordingContext";
import {WizardStepContext} from "./contexts";

interface WizardNavigatorProps {
	maxStep: number;
	onComplete: () => void;
}

export const WizardNavigator = forwardRef<HTMLButtonElement, WizardNavigatorProps>(
	({maxStep, onComplete}, ref) => {
		const {
			step,
			setStep,
			photoEnabled,
			ocrEnabled,
			formLocked,
			formReset,
			setFormReset,
		} = useContext(WizardStepContext);

		function handleBack() {
			const newStep = step - 1;
			if (newStep > -1) {
				setStep(newStep);
			}
		}

		function handleReset() {
			setFormReset(formReset + 1);
			setStep(photoEnabled ? 0 : 1);
		}

		function handleComplete() {
			onComplete();
		}

		return (
			<MobileStepper
				variant="dots"
				position="static"
				steps={photoEnabled ? maxStep + 1 : maxStep}
				activeStep={photoEnabled ? step : step - 1}
				backButton={
					<BackButtons
						step={step}
						formLocked={formLocked}
						photoEnabled={photoEnabled}
						onBack={handleBack}
						onReset={handleReset}
					/>
				}
				nextButton={
					<NextButton
						ref={ref}
						step={step}
						formLocked={formLocked}
						ocrEnabled={ocrEnabled}
						onComplete={handleComplete}
					/>
				}
			/>
		);
	},
);

interface BackButtonsProps {
	step: number;
	formLocked: boolean;
	photoEnabled: boolean;
	onBack: () => void;
	onReset: () => void;
}

function BackButtons({step, formLocked, photoEnabled, onBack, onReset}: BackButtonsProps) {
	const {t} = useLocalization();

	const disabled = formLocked || (!photoEnabled && step === 1) || step === 0;
	return (
		<div>
			<Button
				size="small"
				disabled={disabled}
				onClick={onBack}
			>
				{t("back")}
			</Button>
			<Button
				size="small"
				disabled={disabled}
				onClick={onReset}
			>
				{t("reset")}
			</Button>
		</div>
	);
}

interface NextButtonsProps {
	step: number;
	formLocked: boolean;
	ocrEnabled: boolean;
	onComplete: () => void;
}

const NextButton = forwardRef<HTMLButtonElement, NextButtonsProps>(
	({step, formLocked, ocrEnabled, onComplete}, ref) => {
		const {t} = useLocalization();

		const {recording} = useContext(CameraRecordingContext);

		const nextLabel = useMemo(() => {
			switch (step) {
				case 0:
					return t("skip");
				case 3:
					return t("confirm");
				default:
					return t("next");
			}
		}, [step]);

		useHotkey({key: "Enter", ctrlKey: true}, () => {
			if (!formLocked) {
				onComplete();
			}
		});

		return (
			<div>
				{(step !== 0 && step !== 3) && (
					<Button
						size="small"
						disabled={formLocked}
						onClick={onComplete}
					>
						{t("complete")}
					</Button>
				)}
				<Button
					type="submit"
					size="small"
					variant={step === 3
						? "contained"
						: "text"}
					color={step === 3
						? "primary"
						: undefined}
					disabled={formLocked}
				>
					{nextLabel}
				</Button>
				<Button
					ref={ref}
					variant="contained"
					color="primary"
					disabled={!recording}
					sx={{
						ml: 1,
						display: step === 0 ? undefined : "none",
					}}
				>
					{ocrEnabled
						? (
							<Fragment>
								<TextFieldsIcon />
								{t("deliveries.create.pictureStep.action.analyze")}
							</Fragment>
						)
						: (
							<Fragment>
								<AddAPhotoIcon />
								{t("next")}
							</Fragment>
						)}
				</Button>
			</div>
		);
	},
);
