import * as React from "react";
import {createElement, useCallback, useEffect, useRef, useState} from "react";
import {Autocomplete, IconButton, InputAdornment, OutlinedTextFieldProps, TextField} from "@mui/material";
import {Search} from "@mui/icons-material";
import {SelectOcrElementDialog} from "./SelectOcrElementDialog";
import {OcrResponse} from "../../domain/Ocr";

interface OcrInputProps extends Omit<OutlinedTextFieldProps, "onChange" | "variant"> {
    ocr?: OcrResponse;
    onChange: (value: string) => void;
    numeric?: boolean;
    defaultFocused?: boolean;
    suggestions?: { label: string, value: string }[];

    /**
     * Capture the Enter key if this input is focused.
     * Useful for fields that are commonly used with barcode scanners, but the form should not be submitted.
     */
    captureEnter?: boolean;
}

export function OcrInput({onChange, ocr, numeric, defaultFocused, captureEnter, suggestions, ...props}: OcrInputProps) {
    const inputRef = useRef<HTMLInputElement>(null);

    const [selectOcrFragment, setSelectOcrFragment] = useState<boolean>(false);
    const [value, setValue] = useState('');

    useEffect(() => {
        if (value !== props.value) {
            setValue(props.value as string);
        }
    }, [props.value])

    useEffect(() => {
        if(defaultFocused && inputRef.current) {
            inputRef.current.focus();
        }
    }, [defaultFocused, inputRef]);

    const handleChange = useCallback((value: string) => {
        const v = (numeric) ? value.replace(/[^0-9,]/g, '') : value;
        setValue(v);
        onChange(v);
        setSelectOcrFragment(false);
    }, [numeric, onChange]);

    const endAdornment = ocr ?
        <InputAdornment position="end">
            <IconButton onClick={() => setSelectOcrFragment(true)}>
                <Search />
            </IconButton>
        </InputAdornment> : undefined;

    useEffect(() => {
        if (captureEnter) {
            const handler = (e: KeyboardEvent) => {
                if (inputRef.current == document.activeElement && e.key == "Enter") {
                    e.stopPropagation();
                    e.preventDefault();
                }
            }
            window.addEventListener("keypress", handler, {capture: true});
            return () => window.removeEventListener("keypress", handler);
        }
    }, [captureEnter]);

    return (
        <React.Fragment>
            {!suggestions && (
                <TextField
                    inputRef={inputRef}
                    onChange={ev => handleChange(ev.target.value)}
                    variant="outlined"
                    fullWidth
                    {...props}
                    InputProps={{endAdornment: endAdornment}}
                />
            )}

            {suggestions && (
                <Autocomplete
                    freeSolo
                    disableClearable
                    options={suggestions.map(({label}) => label)}
                    value={value}
                    onChange={(ev, newValue) => handleChange(newValue)}
                    inputValue={value}
                    onInputChange={(ev, newValue) => handleChange(newValue)}
                    renderInput={params=> (
                        <TextField
                            {...params}
                            {...props}
                            value={value}
                            inputRef={inputRef}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: endAdornment,
                                type: 'search',
                            }}
                        />
                    )}
                />
            )}

            {ocr && (
                <SelectOcrElementDialog open={selectOcrFragment} ocr={ocr} onSelect={(text: string) => {
                    onChange(text);
                    setValue(text);
                    setSelectOcrFragment(false);
                }} onCancel={() => setSelectOcrFragment(false)} />
            )}
        </React.Fragment>
    );
}
