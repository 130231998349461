import {Box, Checkbox, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography,} from "@mui/material";
import {TextField} from "@variocube/app-ui";
import * as React from "react";
import {createElement} from "react";
import {useLocalization} from "../../i18n";
import {LHelpButton} from "../LHelpButton";
import {SimpleCheckBox} from "../SimpleCheckBox";
import {IReturnDeliveryConfig} from "./ReturnDeliveryConfig";

interface ReturnProps {
	returnConfig: IReturnDeliveryConfig;
	onChange: (retPar: IReturnDeliveryConfig) => void;
}

export function ReturnDelivery({returnConfig, onChange}: ReturnProps) {
	const {t} = useLocalization();
	const showTitle = false;

	function handleEnabled(checked: boolean) {
		onChange({...returnConfig, enabled: checked});
	}

	function handleParameterEnabled(event: React.ChangeEvent<HTMLInputElement>, index: number) {
		const p = {...returnConfig};
		p.parameter[index].enabled = event.target.checked;
		onChange(p);
	}

	function handleParameterMandatory(event: React.ChangeEvent<HTMLInputElement>, index: number) {
		let p = {...returnConfig};
		p.parameter[index].mandatory = event.target.checked;
		onChange(p);
	}

	function handleParameterVerify(event: React.ChangeEvent<HTMLInputElement>, index: number) {
		const p = {...returnConfig};
		p.parameter[index].verify = event.target.checked;
		onChange(p);
	}

	function handleRequiresAuthorizationEnabled(checked: boolean) {
		onChange({...returnConfig, returnRequiresAuthorization: checked});
	}

	function authorizationConfigChanged(authorizationProviderHint: string) {
		onChange({...returnConfig, authorizationProviderHint});
	}

	return (
		<Grid item xs={12}>
			<Stack spacing={2}>
				<Box>
					<SimpleCheckBox
						label={t("returns.enabled")}
						checked={returnConfig.enabled}
						onChange={handleEnabled}
					/>
					<LHelpButton helpPage="Tenant_Settings" helpAnchor="Return_Deliveries" language="" />
				</Box>
				{returnConfig.enabled && (
					<Box>
						<SimpleCheckBox
							label={t("returns.requiresAuthorization")}
							checked={returnConfig.returnRequiresAuthorization}
							onChange={handleRequiresAuthorizationEnabled}
						/>
						<LHelpButton helpPage="Tenant_Settings" helpAnchor="Return_Deliveries" language="" />
					</Box>
				)}
				{returnConfig.enabled && returnConfig.returnRequiresAuthorization && (
					<Box>
						<TextField
							fullWidth
							variant="outlined"
							label={t("returns.authorizationHintLabel")}
							placeholder="Please, scan your library QR Code!"
							value={returnConfig.authorizationProviderHint}
							onChange={authorizationConfigChanged}
						/>
					</Box>
				)}

				{showTitle && <Typography variant="h6">{t("returns.plural")}</Typography>}
				{returnConfig.enabled && (
					<Box>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>{t("returns.parameter")}</TableCell>
									<TableCell width="10%" align="center">{t("returns.enabled")}</TableCell>
									<TableCell width="10%" align="center">{t("returns.mandatory")}</TableCell>
									<TableCell width="10%" align="center">{t("returns.verify")}</TableCell>
									<TableCell width="100%"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{returnConfig.parameter.map((p, index) => {
									return (
										<TableRow key={p.nameId} hover={returnConfig.enabled}>
											<TableCell>{t(p.nameId as any)}</TableCell>
											<TableCell align="center">
												<Checkbox
													checked={p.enabled}
													onChange={(event) => handleParameterEnabled(event, index)}
												/>
											</TableCell>
											<TableCell align="center">
												<Checkbox
													checked={p.mandatory}
													disabled={!p.enabled}
													onChange={(event) => handleParameterMandatory(event, index)}
												/>
											</TableCell>
											<TableCell align="center">
												{(p.nameId == "returns.email" || p.nameId == "returns.phone")
													&& (
														<Checkbox
															checked={p.verify}
															disabled={!p.enabled}
															onChange={(event) => handleParameterVerify(event, index)}
														/>
													)}
											</TableCell>
											<TableCell width="100%"></TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</Box>
				)}
			</Stack>
		</Grid>
	);
}
