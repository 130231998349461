import { Grid } from "@mui/material";
import { createElement } from "react";
import { OutOfOffice } from "../../domain/OutOfOffice";
import { BooleanDisplay } from "../BooleanDisplay";
import { DateComponent } from "../DateComponent";
import { LabeledItem } from "../LabeledItem";
import { useLocalization } from "../../i18n";
import { OutOfOfficeRecipientsView } from "./OutOfOfficeRecipientsView";


interface OutOfOfficeViewPorps {
	outOfOffice: OutOfOffice | undefined;
}

export function OutOfOfficeView(props: OutOfOfficeViewPorps) {
	const { outOfOffice } = props;
	const { t } = useLocalization();


	if (outOfOffice && outOfOffice.active)
		return (
			<LabeledItem label={t("recipients.outOfOfficeAssistant.title")}>
				<Grid container spacing={1}>
					<Grid item>
						<BooleanDisplay value={outOfOffice.active || false} />
					</Grid>
					{(outOfOffice.from || outOfOffice.to)
						&& (
							<Grid item>
								<DateComponent date={outOfOffice.from} /> - {" "}
								<DateComponent date={outOfOffice.to} />
							</Grid>
						)}
					{outOfOffice && outOfOffice.substituteEmail &&
						<OutOfOfficeRecipientsView substituteEmail={outOfOffice.substituteEmail} />
					}
				</Grid>
			</LabeledItem>);
	return (<span></span>);
}
