import {createElement, useEffect, useState} from "react";
import {gs} from "../../../theme";
import {Grid, Typography} from "@mui/material";
import {LabeledData} from "../../LabeledData";
import {useLocalization} from "../../../i18n";
import {LabeledItem} from "../../LabeledItem";
import {useTenant} from "../../TenantContextProvider";
import { OutOfOfficeView } from "../../recipients/OutOfOfficeView";
import { OutOfOffice } from "../../../domain/OutOfOffice";
import { OutOfOfficeProvider } from "../../../domain/OutOfOfficeProvider";

interface RecipientStepSelectedDisplayProps {
    name: string;
    email: string;
    phone: string;
    department?: string;
    building?: string;
    pickupKey?: string;
    locale?: string;
}

export function RecipientStepSelectedDisplay(props: RecipientStepSelectedDisplayProps) {
    const {name, email, phone, department, building, pickupKey, locale} = props;
    const {t} = useLocalization();
    const tenant = useTenant();
    const [outOfOffice, setOutOfOffice] = useState<OutOfOffice>();

    useEffect(() => {
		const r = async () => {
			if (email) {
				let o: OutOfOffice = await OutOfOfficeProvider.get(tenant.centerId, email);
				setOutOfOffice(o);
			}
		};
		r();
	}, [email]);
    
    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <LabeledData label={t("deliveries.create.recipientStep.name")}>
                    {name}
                </LabeledData>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={gs}>
                    <LabeledItem label={t("deliveries.create.recipientStep.email")}>
                        {email}
                    </LabeledItem>
                    {phone &&
                        <LabeledItem label={t("deliveries.create.recipientStep.phone")}>
                            {phone}
                        </LabeledItem>
                    }
                </Grid>
            </Grid>
            {department &&
                <LabeledItem label={t("recipients.department")}>
                    <Typography variant="body1">{department}</Typography>
                </LabeledItem>
            }
            {building &&
                <LabeledItem label={t("recipients.building")}>
                    <Typography variant="body1">{building}</Typography>
                </LabeledItem>
            }
            {(tenant.recipientPickupKeyExposed && pickupKey) &&
                <LabeledItem label={t("deliveries.create.recipientStep.pickupKey")}>
                    <pre style={{margin: 0}}>{pickupKey}</pre>
                </LabeledItem>
            }

            {locale &&
                <LabeledItem label={t("recipients.locale")}>
                    <Typography variant="body1">{locale}</Typography>
                </LabeledItem>
            }

            {(outOfOffice && outOfOffice.active)
						&& (<OutOfOfficeView outOfOffice={outOfOffice}></OutOfOfficeView>)}

        </Grid>
    );

}
