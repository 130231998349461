import {makeStyles} from "@mui/styles";
import {createElement, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {Bookmark} from "../../../domain/Bookmark";
import {BookmarksProvider} from "../../../domain/BookmarksProvider";
import {Delivery, DeliveryType} from "../../../domain/Delivery";
import {ShipmentsProvider} from "../../../domain/ShipmentsProvider";
import {useLocalization} from "../../../i18n";
import {DateComponent, Display} from "../../DateComponent";
import {IPrintViewConfig, PrintablesEnum} from "../../settings/PrintViewConfig";
import {useTenantId, useTenantUser} from "../../TenantContextProvider";
import {WrappedBarCode} from "../../WrappedBarCode";
import {WrappedQRCode} from "../../WrappedQRCode";
import {DeliveryConditionDisplay} from "../DeliveryConditionDisplay";

interface DeliveryPrintViewProps {
	delivery: Delivery;
	printViewConfig: IPrintViewConfig;
	onReadyToPrint: () => void;
}

export function DeliveryPrintView(props: DeliveryPrintViewProps) {
	const {delivery, printViewConfig, onReadyToPrint} = props;
	const {t} = useLocalization();
	const user = useTenantUser();
	const tenantId = useTenantId();

	const [inboundDelivery, setInboundDelivery] = useState<Delivery>();
	const [barcodeRendered, setBarcodeRendered] = useState(false);
	const [inboundDeliveryFetched, setInboundDeliveryFetched] = useState(false);
	const [bookmark, setBookmark] = useState<Bookmark>();
	const [bookmarkFetched, setBookmarkFetched] = useState(false);

	useEffect(() => {
		setBarcodeRendered(false);
		setInboundDeliveryFetched(delivery.shipment == undefined);
		setBookmarkFetched(delivery.receivingDepartment == undefined);
		if (delivery.shipment && delivery.delivery.deliveryType === DeliveryType.Outbound) {
			ShipmentsProvider.get(delivery.tenantId, delivery.shipment.uuid)
				.then(s => {
					setInboundDelivery(s.inboundDelivery);
					setInboundDeliveryFetched(true);
				});
		}
		if (delivery.receivingDepartment) {
			BookmarksProvider.get(tenantId, delivery.receivingDepartment)
				.then((b) => {
					setBookmark(b);
					setBookmarkFetched(true);
				});
		}
	}, [delivery]);

	useEffect(() => {
		if (barcodeRendered && inboundDeliveryFetched && bookmarkFetched) {
			onReadyToPrint();
		}
	}, [onReadyToPrint, barcodeRendered, inboundDeliveryFetched, bookmarkFetched]);

	const isPrint = useCallback((key: PrintablesEnum): boolean => {
		const configuration = printViewConfig.printable.find((config) => config.key === key);
		if (configuration) {
			return configuration.print;
		}
		return true;
	}, [printViewConfig]);

	const label = useMemo<string>(
		() => delivery?.order?.description || delivery?.order?.orderId || delivery?.id || "",
		[
			delivery,
		],
	);

	const recipients = useMemo<string>(() => {
		if (delivery.recipients && delivery.recipients.length > 0) {
			return delivery.recipients.map((recipient) => {
				if (recipient.recipientName) {
					return recipient.recipientName;
				} else if (recipient.email) {
					return recipient.email;
				} else if (recipient.phone) {
					return recipient.phone;
				}
				return "#unknown#";
			}).join(", ");
		} else if (delivery.externalRecipient) {
			return [delivery.externalRecipient.company, delivery.externalRecipient.name]
				.filter((s) => Boolean(s))
				.join(", ");
		}
		return t("deliveries.recipients.unknown");
	}, [delivery, t]);

	const recipientBuilding = useMemo<string>(() => {
		if (delivery.recipients && delivery.recipients.length > 0) {
			return delivery.recipients.map((recipient) => {
				if (recipient.building) {
					return recipient.building;
				}
				return "";
			}).join(", ");
		}
		return "";
	}, [delivery]);

	function formatDestination(delivery: Delivery) {
		if (delivery.storage && (delivery.storage.destinationName && !delivery.storage.manualHandoverRequired)) {
			if (delivery.delivery.boxNumber) {
				return `${delivery.delivery.boxNumber} - ${delivery.storage.destinationName}`;
			}
			return delivery.storage.destinationName;
		}
		return t("deliveries.manualHandover.title");
	}

	const classes = useStyles();

	return (
		<div className={classes.PrintContainer}>
			{isPrint(PrintablesEnum.Header) && (
				<div className={classes.Header}>
					<div className={classes.Overline}>
						{"Sendungsbegleitschein"}
					</div>
					{label
						&& <div className={classes.Title}>{label}</div>}
				</div>
			)}
			<div className={classes.BarCode}>
				{(delivery.storage && delivery.storage.storageCode)
					&& (
						<Fragment>
							{printViewConfig.barcode.type == "QRCode"
								&& (
									<WrappedQRCode
										value={delivery.storage.storageCode}
										height={printViewConfig.barcode.height || 0}
										width={printViewConfig.barcode.width || 0}
										margin={3}
										onRender={() => setBarcodeRendered(true)}
									/>
								)}

							{printViewConfig.barcode.type == null
								&& (
									<WrappedBarCode
										value={delivery.storage.storageCode}
										height={printViewConfig.barcode.height || 0}
										width={printViewConfig.barcode.width || 0}
										margin={3}
										onRender={() => setBarcodeRendered(true)}
									/>
								)}
							{isPrint(PrintablesEnum.StorageCode)
								&& (
									<Fragment>
										<div className={classes.StorageCodeLabel}>
											{t("deliveries.storageCode")}
										</div>
										<div className={classes.StorageCode}>
											{delivery.storage.storageCode}
										</div>
									</Fragment>
								)}
						</Fragment>
					)}
			</div>
			{(isPrint(PrintablesEnum.Recipients) && recipients)
				&& (
					<div className={classes.Field}>
						<div className={classes.Label}>
							{t("deliveries.recipients.plural")}
						</div>
						<div className={classes.Value}>
							{recipients}
						</div>
					</div>
				)}
			{(isPrint(PrintablesEnum.Department) && delivery.receivingDepartment)
				&& (
					<div className={classes.Field}>
						<div className={classes.Label}>
							{t("deliveries.department")}
						</div>
						<div className={classes.Value}>
							{bookmark?.label}
						</div>
					</div>
				)}
			{(isPrint(PrintablesEnum.Building) && recipientBuilding)
				&& (
					<div className={classes.Field}>
						<div className={classes.Label}>
							{t("recipients.building")}
						</div>
						<div className={classes.Value}>
							{recipientBuilding}
						</div>
					</div>
				)}
			{isPrint(PrintablesEnum.DeliveryBox)
				&& (
					<Fragment>
						{(delivery.shipment && inboundDelivery)
							&& (
								<div className={classes.Field}>
									<div className={classes.Label}>
										{t("deliveries.storage.pickupAt")}
									</div>
									<div className={classes.Value}>
										{formatDestination(delivery)}
									</div>
								</div>
							)}
						<Fragment>
							<div className={classes.Field}>
								<div className={classes.Label}>
									{t("deliveries.storage.destination")}
								</div>
								<div className={classes.Value}>
									{inboundDelivery ? formatDestination(inboundDelivery) : formatDestination(delivery)}
								</div>
							</div>
						</Fragment>
					</Fragment>
				)}
			{(isPrint(PrintablesEnum.Delivery) && label) && (
				<div className={classes.Field}>
					<div className={classes.Label}>
						{t("deliveries.singular")}
					</div>
					<div className={classes.Value}>
						{label}
					</div>
				</div>
			)}
			{(isPrint(PrintablesEnum.OrderId) && delivery.order && delivery.order.orderId) && (
				<div className={classes.Field}>
					<div className={classes.Label}>
						{t("deliveries.order.orderId")}
					</div>
					<div className={classes.Value}>
						{delivery.order.orderId}
					</div>
				</div>
			)}
			{(isPrint(PrintablesEnum.Description) && delivery.order && delivery.order.description) && (
				<div className={classes.Field}>
					<div className={classes.Label}>
						{t("deliveries.order.description")}
					</div>
					<div className={classes.Value}>
						{delivery.order.description}
					</div>
				</div>
			)}
			{(isPrint(PrintablesEnum.Sender) && delivery.delivery.sender) && (
				<div className={classes.Field}>
					<div className={classes.Label}>
						{t("deliveries.sender")}
					</div>
					<div className={classes.Value}>
						{delivery.delivery.sender}
					</div>
				</div>
			)}
			{(isPrint(PrintablesEnum.Carrier) && delivery.delivery.carrier) && (
				<div className={classes.Field}>
					<div className={classes.Label}>
						{t("deliveries.carrier")}
					</div>
					<div className={classes.Value}>
						{delivery.delivery.carrier}
					</div>
				</div>
			)}
			{(isPrint(PrintablesEnum.ParcelId) && delivery.delivery.parcelId) && (
				<div className={classes.Field}>
					<div className={classes.Label}>
						{t("deliveries.parcelId")}
					</div>
					<div className={classes.Value}>
						{delivery.delivery.parcelId}
					</div>
				</div>
			)}
			{(isPrint(PrintablesEnum.Condition) && delivery.delivery.condition) && (
				<div className={classes.Field}>
					<div className={classes.Label}>
						{t("deliveries.condition")}
					</div>
					<div className={classes.Value}>
						<DeliveryConditionDisplay value={delivery.delivery.condition} />
					</div>
				</div>
			)}
			{(isPrint(PrintablesEnum.User) && user) && (
				<div className={classes.Field}>
					<div className={classes.Label}>
						{t("users.singular")}
					</div>
					<div className={classes.Value}>
						{user.lastName || user.email || user.username}
					</div>
				</div>
			)}
			{(isPrint(PrintablesEnum.CreatedAt) && delivery.delivery.createdAt) && (
				<div className={classes.Field}>
					<div className={classes.Label}>
						{t("deliveries.createdAt")}
					</div>
					<div className={classes.Value}>
						<DateComponent date={delivery.delivery.createdAt} display={Display.Date} />
					</div>
				</div>
			)}
			{(isPrint(PrintablesEnum.CreatedBy) && delivery.delivery.createdBy) && (
				<div className={classes.Field}>
					<div className={classes.Label}>
						{t("deliveries.createdBy")}
					</div>
					<div className={classes.Value}>
						{delivery.delivery.createdBy}
					</div>
				</div>
			)}
			{(isPrint(PrintablesEnum.CostCenter) && delivery.deliveryDispatchDetails
				&& delivery.deliveryDispatchDetails.costCenter) && (
				<div className={classes.Field}>
					<div className={classes.Label}>
						{t("dispatch.details.costCenter")}
					</div>
					<div className={classes.Value}>
						{delivery.deliveryDispatchDetails.costCenter}
					</div>
				</div>
			)}
		</div>
	);
}

/*
BioNTech: 62mm * 40mm = 222 * 148px
Helvetia: 104mm * 76mm = 378 * 282px
Liebherr: 152mm * 74mm = 555 * 276px
A4:		  210mm * 297mm = 782 * 1105px
 */

const useStyles = makeStyles(theme => ({
	PrintContainer: {
		display: "flex",
		flexWrap: "wrap",
		maxWidth: "100vw",
		maxHeight: "100vh",
		margin: "15mm",
		gap: "2.5em",
		"@media (max-width: 152mm)": {
			flexDirection: "column",
			margin: 0,
			gap: 0,
		},
		"@media (max-width: 104mm)": {
			flexDirection: "column",
			margin: 0,
			gap: 0,
		},
		"@media (max-width: 62mm)": {
			flexDirection: "row",
			margin: 0,
			gap: 0,
		},
	},
	Header: {
		textAlign: "center",
		width: "100%",
		paddingBottom: "3.5em",
		"@media (max-width: 152mm)": {
			display: "none",
		},
	},
	Overline: {
		textTransform: "uppercase",
	},
	Title: {
		fontSize: "1.5em",
		fontWeight: 500,
	},
	/* Special formatting, because it is on top center for small labels and top left corner for big labels */
	BarCode: {
		paddingRight: "3.5em",
		"@media (max-width: 152mm)": {
			width: "50%",
			padding: "0.5em",
		},
		"@media (max-width: 104mm)": {
			width: "50%",
			padding: "0.3em",
		},
		"@media (max-width: 62mm)": {
			width: "100%",
			padding: 0,
		},
	},
	/* Special formatting, because it sits just below the barcode */
	StorageCodeLabel: {
		width: "100%",
		textAlign: "center",
		textTransform: "uppercase",
		"@media (max-width: 152mm)": {
			overflow: "hidden",
			fontSize: "0.9em",
			textOverflow: "ellipsis",
		},
		"@media (max-width: 104mm)": {
			overflow: "hidden",
			fontSize: "0.8em",
			textOverflow: "ellipsis",
		},
		"@media (max-width: 62mm)": {
			display: "none",
		},
	},
	StorageCode: {
		width: "100%",
		textAlign: "center",
		fontWeight: 500,
	},

	/* Common formatting for all other data */
	Field: {
		minWidth: "25%",
		maxWidth: "50%",
		"@media (max-width: 152mm)": {
			width: "50%",
			padding: "0.5em",
		},
		"@media (max-width: 104mm)": {
			width: "50%",
			maxWidth: "50%",
			padding: "0.3em",
		},
		"@media (max-width: 62mm)": {
			width: "50%",
			maxWidth: "50%",
			padding: 0,
			overflow: "hidden",
			fontSize: "0.8em",
		},
	},
	Label: {
		width: "100%",
		textTransform: "uppercase",
		"@media (max-width: 152mm)": {
			overflow: "hidden",
			fontSize: "0.9em",
			textOverflow: "ellipsis",
		},
		"@media (max-width: 104mm)": {
			overflow: "hidden",
			fontSize: "0.8em",
			textOverflow: "ellipsis",
		},
		"@media (max-width: 62mm)": {
			overflow: "hidden",
			fontSize: "0.6em",
			textOverflow: "ellipsis",
		},
	},
	Value: {
		fontWeight: 500,
		fontSize: "1.1em",
		"@media (max-width: 62mm)": {
			fontWeight: 400,
			fontSize: "1em",
			overflow: "hidden",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
		},
	},
}));
