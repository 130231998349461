import * as React from "react";
import {createElement,Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {Delivery, DeliveryFilterRequest, Recipient} from "../../domain/Delivery";
import {useNavigate} from "react-router";
import {Box, Button, Checkbox, FormControlLabel, Grid, Paper, TableCell, TableRow, Typography} from "@mui/material";
import {ColumnType, ContentTable, Page, PagingImpl, PagingSettings} from "@variocube/app-ui";
import {gs} from "../../theme";
import {DeliveryStateDisplay} from "./DeliveryStateDisplay";
import {DateComponent} from "../DateComponent";
import {DeliveriesProvider} from "../../domain/DeliveriesProvider";
import {Cube} from "../../domain/Cube";
import {AppStorage} from "../uis";
import {DeliveryTypeDisplay} from "./DeliveryTypeDisplay";
import {useLocalization} from "../../i18n";
import {BooleanDisplay} from "../BooleanDisplay";
import {DeliveryConditionDisplay} from "./DeliveryConditionDisplay";
import {Loading} from "../Loading";
import {RefreshRounded} from "@mui/icons-material";
import {useTenantId} from "../TenantContextProvider";


interface DeliveryListProps  {
    title: string
    filter:DeliveryFilterRequest,
    columns: AppStorage<ColumnType>
    cube?: Cube,
    paging: PagingImpl,
    onLoaded? :  () => void;
}

const MiniImagePreview = ({url}: {url: string}) => {
    return (
        <img src={url} alt="Preview" style={{ maxWidth: 50, maxHeight: 50 }}/>
    )
}


const DeliveryRow  = ({delivery, columns}: { delivery: Delivery, columns: ColumnType }) => {
    const navigate = useNavigate();
    const tenantId = useTenantId();

    const isColumnActive = (name: string) => (columns[name] && columns[name].show);

    const generateRecipientDisplay = (recipients: Recipient[]) => {
        if(!recipients || recipients.length == 0) return '';
        return recipients.map(r => r.recipientName).join(', ');
    }

    const handleDeliveryClicked = (delivery: Delivery) => {
        navigate(`/${tenantId}/deliveries/${delivery.id}`);
    }


    return (
        <TableRow hover onClick={() => handleDeliveryClicked(delivery)}>
            {isColumnActive('delivery.state') && <TableCell><DeliveryStateDisplay deliveryState={delivery.delivery.state} size="small" /></TableCell>}
            {isColumnActive('delivery.deliveryType') && <TableCell><DeliveryTypeDisplay deliveryType={delivery.delivery.deliveryType} size="small" /></TableCell>}
            {isColumnActive('delivery.parcelId') && <TableCell>{delivery.delivery.parcelId}</TableCell>}
            {isColumnActive('delivery.condition') && <TableCell><DeliveryConditionDisplay value={delivery.delivery.condition} /></TableCell>}
            {isColumnActive('delivery.weight') && <TableCell>{delivery.delivery.weight}</TableCell>}
            {isColumnActive('delivery.sender') && <TableCell>{delivery.delivery.sender}</TableCell>}
            {isColumnActive('delivery.carrier') && <TableCell>{delivery.delivery.carrier}</TableCell>}

            {isColumnActive('order.description') && <TableCell>{delivery.order.description}</TableCell>}
            {isColumnActive('order.deliveryNoteId') && <TableCell>{delivery.order.deliveryNoteId}</TableCell>}
            {isColumnActive('order.orderId') && <TableCell>{delivery.order.orderId}</TableCell>}
            {isColumnActive('order.orderPositions') && <TableCell>{delivery.order.orderPositions.map(p => p.positionLabel).join(', ')}</TableCell>}
            {isColumnActive('order.attachments') && <TableCell>{delivery.order.attachments.map(a => a.name).join(', ')}</TableCell>}
            {isColumnActive('metadata.foreignId') && <TableCell>{delivery.metadata?.foreignId}</TableCell>}
            {isColumnActive('metadata.callbackUrl') && <TableCell>{delivery.metadata?.callbackUrl}</TableCell>}

            {isColumnActive('recipients') && <TableCell>{generateRecipientDisplay(delivery.recipients)}</TableCell>}

            {isColumnActive('delivery.createdAt') && <TableCell><DateComponent date={delivery.delivery.createdAt} /></TableCell>}
            {isColumnActive('delivery.storedAt') && <TableCell>{delivery.delivery.storedAt && <DateComponent date={delivery.delivery.storedAt} />}</TableCell>}
            {isColumnActive('delivery.pickedUpAt') && <TableCell>{delivery.delivery.pickedUpAt && <DateComponent date={delivery.delivery.pickedUpAt} />}</TableCell>}

            {isColumnActive('receivingDepartment') && <TableCell>{delivery.receivingDepartment}</TableCell>}

            {isColumnActive('storage.storageTimeMax') && <TableCell>{delivery.storage?.storageTimeMax}</TableCell>}
            {isColumnActive('storage.hazardousGoods') && <TableCell>{delivery.storage?.hazardousGoods}</TableCell>}
            {isColumnActive('storage.destination') && <TableCell>{delivery.storage?.destination}</TableCell>}
            {isColumnActive('storage.destinationName') && <TableCell>{delivery.storage?.destinationName}</TableCell>}
            {isColumnActive('storage.destinationDescription') && <TableCell>{delivery.storage?.destinationDescription}</TableCell>}
            {isColumnActive('storage.storageKey') && <TableCell>{delivery.storage?.storageKey}</TableCell>}
            {isColumnActive('storage.storageCode') && <TableCell>{delivery.storage?.storageCode}</TableCell>}
            {isColumnActive('storage.manualHandoverRequired') && <TableCell>{delivery.storage?.manualHandoverRequired !== undefined && <BooleanDisplay value={delivery.storage?.manualHandoverRequired} />}</TableCell>}
        </TableRow>
    );
}


export const DeliveryListView = (props: DeliveryListProps) => {
    const {t} = useLocalization();
    const tenantId = useTenantId();

    const [page, setPage] = useState<Page<Delivery>>();
    const [columns, setColumns] = useState<ColumnType>();

    const [pageable, setPageable] = useState<PagingSettings<keyof typeof baseColumns>>(initialPaging() as PagingSettings<keyof typeof baseColumns>);
    const [inProgress, setInProgress] = useState(false);
    const [loadError, setLoadError] = useState(false);

    const baseColumns: ColumnType = useMemo<ColumnType>(() => ({
        'delivery.state': { show: true, name: t('deliveries.deliveryState') },
        'delivery.deliveryType': { show: false, name: t('deliveries.deliveryType') },
        'delivery.parcelId': { show: false, name: t('deliveries.parcelId') },
        'delivery.condition': { show: false, name: t('deliveries.condition') },
        'delivery.weight': { show: false, name: t('deliveries.weight') },
        'delivery.sender': { show: false, name: t('deliveries.sender') },
        'delivery.carrier': { show: false, name: t('deliveries.carrier') },
        'order.description': { show: true, name: t('deliveries.singular') },
        'order.deliveryNoteId': { show: false, name: t('deliveries.order.deliveryNoteId') },
        'order.orderId': { show: false, name: t('deliveries.order.orderId') },
        'order.orderPositions': { show: false, name: t('positions'), unsortable: true },
        'order.attachments': { show: false, name: t('deliveries.attachments.plural'), unsortable: true },
        'metadata.foreignId': { show: false, name: t('deliveries.thirdParty.foreignId') },
        'metadata.callbackUrl': { show: false, name: t('deliveries.thirdParty.callbackUrl') },
        'recipients': { show: true, name: t('deliveries.recipients.plural'), unsortable: true },
        'delivery.createdAt': { show: true, name: t('deliveries.createdAt') },
        'delivery.storedAt': { show: false, name: t('deliveries.storedAt') },
        'delivery.pickedUpAt': { show: false, name: t('deliveries.pickedUpAt') },
        'receivingDepartment': { show: false, name: t('deliveries.receivingDepartment') },
        'storage.storageTimeMax': { show: false, name: t('deliveries.storage.storageTimeMax')},
        'storage.hazardousGoods': { show: false, name: t('deliveries.storage.hazardousGoods')},
        'storage.destination': { show: false, name: t('deliveries.storage.destination')},
        'storage.destinationName': { show: false, name: t('deliveries.storage.destinationName')},
        'storage.destinationDescription': { show: false, name: t('deliveries.storage.destinationDescription')},
        'storage.storageKey': { show: false, name: t('deliveries.thirdParty.storageKey')},
        'storage.storageCode': { show: false, name: t('deliveries.storageCode')},
        'storage.manualHandoverRequired': { show: false, name: t('deliveries.create.recipientStep.manualHandoverRequired')},
    }), [t]);

    useEffect(() => {
        let columns = props.columns.get();
        if (columns == null) {
            props.columns.set(baseColumns);
            columns = baseColumns;
        }
        setColumns(columns);
        setPageable(pageable);
        loadPage(props.filter, props.paging).then();
    }, [props.paging])

    const handleColumnsChange = (c: ColumnType) => {
        setColumns(c);
        props.columns.set(c);
    }
    function initialPaging() {
        const paging_ = props.paging.getSettings();
        if(!paging_.sort) {
            paging_.sort = 'delivery.createdAt';
            paging_.direction = 'desc';
            props.paging.updateSettings(paging_);
        }
        return paging_;
    }

    const loadPage = useCallback(async (filter: DeliveryFilterRequest, paging) => {
       setLoadError(false);
       setInProgress(true);
        try {
            const data = await DeliveriesProvider.list(tenantId, paging, filter);
            setPage(data);
            if (props.onLoaded)
                props.onLoaded();
        } catch (error) {
            console.error('failed to load page', error);
            setLoadError(true);
            setPage(undefined);
        }
        setInProgress(false);
    }, [props.paging]);

    const renderColumnCheckbox = (key: keyof typeof baseColumns) => (
		(columns && columns[key]) ?
            <FormControlLabel key={'column-setting-' + key} label={columns[key].name}
                              color="primary"
                              control={<Checkbox checked={columns[key].show} onChange={e => handleColumnsChange({...columns, [key]: { ...columns[key], show: e.target.checked }})} />}
            /> :
            null
    )


    const handlePagingChange = (pageable: PagingSettings<any>) => {
        props.paging.updateSettings(pageable);
        setPageable(pageable);
        loadPage(props.filter, props.paging).then();

    }

    const handleResetColumnsAndReload = () => {
        props.paging.resetSettings();
        setPageable(props.paging.getSettings());

        props.columns.clear();
        props.columns.set(baseColumns);
        setColumns(baseColumns);

        loadPage(props.filter, props.paging).then();
    }


    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <Paper>
                    <Box sx={{
                        py: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Typography variant={"h6"}
                                    align="center">{props.title + ' ' + props.cube?.name}</Typography>
                        <Box my={0.5}/>
                    </Box>
                </Paper>
                <Paper>
                    {!page && !loadError && <Box py={2}><Loading /></Box>}
                    {loadError && (
                        <Box sx={{ py: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography align="center">{t('loadDataError.message')}</Typography>
                            <Box my={0.5} />
                            <Button color="primary" variant="contained" startIcon={<RefreshRounded />} onClick={handleResetColumnsAndReload}>{t('loadDataError.btn')}</Button>
                        </Box>
                    )}
                    {(page && columns) && (
                        <ContentTable pageable={pageable}
                                      page={page}
                                      inProgress={inProgress}
                                      columns={columns}
                                      onPageableChange={handlePagingChange}
                                      onColumnsChange={handleColumnsChange}
                                      renderTableBody={(
                                          <Fragment>
                                              {page.content.map(d => <DeliveryRow key={d.id} delivery={d}
                                                                                  columns={columns}/>)}
                                          </Fragment>
                                      )}
                                      renderColumnFilter={(
                                          <Fragment>
                                              <Typography variant="overline">{t('delivery')}</Typography>
                                              <Box my={1}/>
                                              {[
                                                  'delivery.state',
                                                  'delivery.deliveryType',
                                                  'delivery.parcelId',
                                                  'delivery.condition',
                                                  'delivery.weight',
                                                  'delivery.sender',
                                                  'delivery.carrier',
                                                  'delivery.createdAt',
                                                  'delivery.storedAt',
                                                  'delivery.pickedUpAt',
                                                  'receivingDepartment',
                                                  'recipients'
                                              ].map(renderColumnCheckbox)}
                                              <Box my={3}/>


                                              <Typography
                                                  variant="overline">{t('deliveries.order.singular')}</Typography>
                                              <Box my={1}/>
                                              {[
                                                  'order.description',
                                                  'order.deliveryNoteId',
                                                  'order.orderId',
                                                  'order.orderPositions',
                                                  'order.attachments'
                                              ].map(renderColumnCheckbox)}
                                              <Box my={3}/>


                                              <Typography
                                                  variant="overline">{t('deliveries.thirdParty.singular')}</Typography>
                                              <Box my={1}/>
                                              {[
                                                  'metadata.foreignId',
                                                  'metadata.callbackUrl'
                                              ].map(renderColumnCheckbox)}
                                              <Box my={3}/>


                                              <Typography
                                                  variant="overline">{t('deliveries.storage.singular')}</Typography>
                                              <Box my={1}/>
                                              {[
                                                  'storage.storageTimeMax',
                                                  'storage.hazardousGoods',
                                                  'storage.destination',
                                                  'storage.destinationName',
                                                  'storage.destinationDescription',
                                                  'storage.storageKey',
                                                  'storage.storageCode',
                                                  'storage.manualHandoverRequired',
                                                  // 'storage.reserveBox'
                                              ].map(renderColumnCheckbox)}
                                              <Box my={3}/>

                                          </Fragment>
                                      )}
                        />
                    )}
                </Paper>
            </Grid>
        </Grid>
    )
}


