import * as React from "react";
import {createElement} from "react";
import {Utilization} from "../../domain/Cube";
import {Grid, Tooltip} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {gs} from "../../theme";
import {useLocalization} from "../../i18n";

interface CubeUtilizationComponentProps {
    utilization: Utilization;
}

export function CubeUtilizationComponent(props: CubeUtilizationComponentProps) {
    const {utilization} = props;
    const classes = useStyles();
    const occupied = utilization.occupied / utilization.total * 100;
    const disabled = utilization.disabled / utilization.total * 100;
    const title = `${utilization.occupied} / ${utilization.total}`;
    return (
        <Tooltip title={<CubeUtilizationTooltipContent utilization={utilization} />}>
            <div className={classes.utilisationContainer}>
                <div style={{width: `${occupied}%`}} className={classes.occupied}></div>
                <div style={{width: `${disabled}%`}} className={classes.disabled}></div>
            </div>
        </Tooltip>
    );
}

function CubeUtilizationTooltipContent(props: CubeUtilizationComponentProps) {
    const {total, occupied, available, disabled} = props.utilization;
    const {t} = useLocalization();
    return(
        <Grid container spacing={gs}>
            <Grid item>
                <label>{t('cubes.utilization.total')}:</label> {total}
            </Grid>
            <Grid item>
                <IndicatorBox color="#8C8838" />
                <label>{t('cubes.utilization.occupied')}:</label> {occupied}
            </Grid>
            <Grid item>
                <IndicatorBox color="#f44336" />
                <label>{t('cubes.utilization.disabled')}:</label> {disabled}
            </Grid>
            <Grid item>
                <IndicatorBox color="#e9ecef" />
                <label>{t('cubes.utilization.available')}:</label> {available}
            </Grid>
        </Grid>
    );
}

interface IndicatorBoxProps {
    color: string;
}

function IndicatorBox(props: IndicatorBoxProps) {
    const classes = useStyles();
    return (<div className={classes.indicatorBox} style={{backgroundColor: props.color}}></div>);
}

const useStyles = makeStyles({
    utilisationContainer: {
        width: '100%',
        display: 'flex',
        height: '1rem',
        overflow: 'hidden',
        fontSize: '0.75rem',
        backgroundColor: '#e9ecef',
        borderColor: 'e9ecef'
    },
    occupied: {
        justifyContent: 'center',
        textAlign: 'center',
        color: '#fff',
        backgroundColor: '#8C8838',
        whiteSpace: 'nowrap',
    },
    disabled: {
        justifyContent: 'center',
        textAlign: 'center',
        color: '#fff',
        backgroundColor: '#f44336',
        whiteSpace: 'nowrap',
    },
    indicatorBox: {
        display: 'inline-block',
        width: '1em', height: '1em',
        marginTop: '0.3em',
        marginRight: '1em'
    }
});
