import {RecipientSummary} from "../../domain/Recipients";
import {useLocalization} from "../../i18n";
import {createElement, Fragment, SyntheticEvent, useCallback, useEffect, useMemo, useState} from "react";
import {Autocomplete, Checkbox, TextField, debounce, Chip, Grid} from "@mui/material";
import {isArray} from "lodash";
import {RecipientsProvider} from "../../domain/RecipientsProvider";
import {useTenantId} from "../TenantContextProvider";
import {Business, Person} from "@mui/icons-material";
import {RecipientSearchDetailsDialog} from "./RecipientSearchDetailDialog";


interface RecipientSearchV2Props {
	label: string;
	values: RecipientSummary[];
	onChange: (value: RecipientSummary[]) => void;
	recipients?: boolean;
	departments?: boolean;
	multiple?: boolean;
	candidates?: RecipientSummary[];
	disabled?: boolean;
	readonly?: boolean;
	required? : boolean;
}

export function RecipientSearchV2(props: RecipientSearchV2Props) {
	const {label, values, onChange, recipients, departments, multiple, candidates, disabled, readonly, required} = props;
	const {t} = useLocalization();
    const tenantId = useTenantId();

	const [needle, setNeedle] = useState<string>("");
	const [filteredOptions, setFilteredOptions] = useState<RecipientSummary[]>(candidates || []);
	const [loading, setLoading] = useState<boolean>(false);
	const [detailSearch, setDetailSearch] = useState<boolean>(false);

	useEffect(() => {
		setFilteredOptions(candidates || []);
		if(!multiple && candidates && candidates.length == 1) {
			onChange([candidates[0]]);
		}
	}, [candidates, multiple]);

	const handleChange = useCallback((event: SyntheticEvent, newValue: RecipientSummary | RecipientSummary[] | null) => {
		if(newValue == null) {
			onChange([]);
			return;
		}
		if(!isArray(newValue)) {
			onChange([newValue]);
			return;
        }
		onChange(newValue);
	}, []);

	const handleNeedleChange = useCallback(async (event: SyntheticEvent, value: string) => {
		setNeedle(value);
		if(!recipients && !departments) {
			throw new Error(`Cannot search for recipients nor for departments: not an allowed state`);
		}
		if(!value) {
			setFilteredOptions(candidates || []);
			return;
		}
		setLoading(true);
		try {
			const response = await RecipientsProvider.lookup(tenantId, [value]);
			const filtered = response.recipients.filter((recipient) =>
				departments && recipient.departmentId || recipients && !recipient.departmentId);
			setFilteredOptions(filtered);
		}catch(e) {
			setFilteredOptions([]);
		}
		setLoading(false);
	}, [candidates, recipients, departments]);

	const handleNeedleChangeDebounced = debounce(handleNeedleChange, 300);

	const performLabelLookup = useCallback((recipient: RecipientSummary) => {
		if(isArray(recipient)) {
			throw new Error("RecipientSearchV2: performLabelLookup: recipient is array");
        }
		if(recipient) {
			return recipient.recipientName;
		}
		return "";
	}, []);

    const performDepartmentLookup = useCallback((recipient: RecipientSummary) => {
        if(recipient && !recipient.departmentId && recipient.department) {
            return recipient.department;
        }
    }, []);

	const value = useMemo<RecipientSummary | null>(() => {
		if(values && values.length > 0) {
			return values[0];
		}
		return null;
	}, [values]);

	const handleDialogSelect = useCallback((selected: RecipientSummary) => {
		if (multiple) {
			onChange([...values, selected]);
		} else {
			onChange([selected]);
		}
		setDetailSearch(false);
	}, [values, multiple]);

	return (
		<Fragment>
			<Autocomplete
				multiple={multiple}
				options={filteredOptions}
				getOptionLabel={performLabelLookup}
				noOptionsText={needle ? t("recipients.noneFound", {needle}) : t("recipients.pleaseType")}
				loading={loading}
				onChange={handleChange}
				value={multiple ? values : value}
				fullWidth
				renderOption={(props, option, {selected}) => (
					<li key={option.recipientId || option.email} {...props}>
						{multiple &&
							<Checkbox sx={{mr: 1}} checked={selected} />
						}
						<Fragment>
							<Grid container spacing={1}>
								<Grid item>
									<span>{performLabelLookup(option)}</span>
								</Grid>
								{performDepartmentLookup(option) &&
									<Grid item>
										<Chip label={performDepartmentLookup(option)} size="small" />
									</Grid>
								}
							</Grid>
						</Fragment>
					</li>
				)}
				renderInput={(params) => (
					<TextField {...params} required={required} autoFocus variant="outlined" label={label} helperText={t("recipients.hint")} />
				)}
				onInputChange={handleNeedleChangeDebounced}
				renderTags={(tags, getTagProps) => (
					<Fragment>
						{tags.map((tag, index) => (
							<Chip avatar={tag.departmentId ? <Business /> : <Person />} label={tag.recipientName} {...getTagProps({ index })} />
						))}
					</Fragment>
				)}
				readOnly={readonly}
				disabled={disabled}
				onDoubleClick={() => setDetailSearch(true)}
			/>
            <RecipientSearchDetailsDialog open={detailSearch} onClose={() => setDetailSearch(false)} onSelected={handleDialogSelect} candidates={candidates} />
		</Fragment>
	);

}
