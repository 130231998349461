import {Box, Chip, Grid, Link} from "@mui/material";
import {Checkbox} from "@variocube/app-ui";
import {createElement, Fragment} from "react";
import {Bookmark} from "../../../domain/Bookmark";
import {Cube} from "../../../domain/Cube";
import {RecipientSummary} from "../../../domain/Recipients";
import {useLocalization} from "../../../i18n";
import {gs} from "../../../theme";
import {parseNumberResilient} from "../../../tools";
import {LabeledItem} from "../../LabeledItem";
import {useTenant} from "../../TenantContextProvider";
import {DeliveryConditionDisplay} from "../DeliveryConditionDisplay";
import {DeliveryImage} from "../DeliveryImage";
import {DeliveryTags} from "../DeliveryTags";
import {WeightDisplay} from "../WeightDisplay";
import {BoxReservation, DeliveryDetailsData, RecipientDetailsData} from "./types";

export const PRINT_LABEL_SETTING = "print-label-setting";

interface StepSummaryProps {
	printLabel: boolean;
	onPrintLabelChange: (checked: boolean) => void;
	imageData?: string;
	recipientDetails?: RecipientDetailsData;
	destination?: Cube;
	boxReservation?: BoxReservation;
	bookmark?: Bookmark;
	deliveryDetails?: DeliveryDetailsData;
}

export function StepSummary(props: StepSummaryProps) {
	const {
		printLabel,
		onPrintLabelChange,
		imageData,
		recipientDetails,
		destination,
		boxReservation,
		bookmark,
		deliveryDetails,
	} = props;
	const {t} = useLocalization();
	const tenant = useTenant();

	function handlePrintLabel(checked: boolean) {
		localStorage.setItem(PRINT_LABEL_SETTING, checked ? "true" : "false");
		onPrintLabelChange(checked);
	}

	const {
		recipient,
		manualHandoverRequired,
		notifyRecipients,
	} = recipientDetails ?? {};
	return (
		<Grid container spacing={3}>
			<Grid
				item
				xs={12}
				sm={imageData ? 6 : 12}
			>
				<CubeSummary {...{manualHandoverRequired, destination, boxReservation}} />

				<Box my={3} />

				<RecipientSummary
					{...{
						specifyNotifyRecipients: tenant.recipientsNotifyingSpecificationRequired,
						recipient,
						bookmark,
						notifyRecipients,
					}}
				/>

				<Box my={3} />

				<DetailsSummary
					{...{deliveryDetails}}
				/>
			</Grid>

			{imageData && (
				<Grid
					item
					xs={12}
					sm={6}
				>
					<DeliveryImage src={imageData} />
				</Grid>
			)}

			<Grid item xs={12}>
				<Checkbox
					label={t("deliveries.create.summary.printLabel")}
					checked={printLabel}
					onChange={handlePrintLabel}
				/>
			</Grid>
		</Grid>
	);
}

interface CubeSummaryProps {
	manualHandoverRequired?: boolean;
	destination?: Cube;
	boxReservation?: BoxReservation;
}

function CubeSummary({manualHandoverRequired, destination, boxReservation}: CubeSummaryProps) {
	const {t} = useLocalization();

	return (
		<Grid container spacing={3}>
			{(!manualHandoverRequired && destination) && (
				<Grid item>
					<LabeledItem label={t("cubes.singular")}>
						{destination.name}
					</LabeledItem>
				</Grid>
			)}
			{boxReservation && (
				<Grid item>
					<LabeledItem label={t("reserveBox.reserveBox")}>
						{boxReservation.boxTypes}
						{boxReservation.boxFeature && <span>-{boxReservation.boxFeature}</span>}
					</LabeledItem>
				</Grid>
			)}
			{manualHandoverRequired && (
				<Grid item>
					<LabeledItem label={t("deliveries.create.recipientStep.manualHandoverRequired")}>
						<Chip label={t("yes")}></Chip>
					</LabeledItem>
				</Grid>
			)}
		</Grid>
	);
}

interface RecipientSummaryProps {
	specifyNotifyRecipients: boolean;
	recipient?: RecipientSummary;
	bookmark?: Bookmark;
	notifyRecipients?: boolean;
}

function RecipientSummary({specifyNotifyRecipients, recipient, bookmark, notifyRecipients}: RecipientSummaryProps) {
	const {t} = useLocalization();

	return (
		<Fragment>
			<Grid container spacing={3}>
				{(recipient && recipient.recipientName) && (
					<Grid item>
						<LabeledItem label={t("deliveries.recipients.singular")}>
							{recipient.recipientName}
						</LabeledItem>
					</Grid>
				)}
				{(bookmark && bookmark.label) && (
					<Grid item>
						<LabeledItem label={t("dispatch.dispatchToDepartment")}>
							{bookmark.label}
						</LabeledItem>
					</Grid>
				)}
			</Grid>

			<Box my={3} />

			{recipient && (
				<Fragment>
					<Grid container spacing={3}>
						{recipient.email && (
							<Grid item>
								<LabeledItem label={t("deliveries.create.recipientStep.email")}>
									<Link href={`mailto:${recipient.email}`}>{recipient.email}</Link>
								</LabeledItem>
							</Grid>
						)}
						{recipient.phone && (
							<Grid item>
								<LabeledItem label={t("deliveries.create.recipientStep.phone")}>
									<Link href={`tel:${recipient.phone}`}>{recipient.phone}</Link>
								</LabeledItem>
							</Grid>
						)}
						{(specifyNotifyRecipients && notifyRecipients) && (
							<LabeledItem label={t("deliveries.create.recipientStep.notifyRecipients")}>
								<Chip label={t("yes")}></Chip>
							</LabeledItem>
						)}
					</Grid>
					<Box my={3} />
				</Fragment>
			)}
		</Fragment>
	);
}

interface DetailsSummaryProps {
	deliveryDetails?: DeliveryDetailsData;
}

function DetailsSummary({deliveryDetails: details}: DetailsSummaryProps) {
	if (!details) {
		return <div />;
	}

	const {t} = useLocalization();

	return (
		<Fragment>
			<Grid container spacing={3}>
				{details.carrier && (
					<Grid item>
						<LabeledItem label={t("deliveries.carrier")}>
							{details.carrier}
						</LabeledItem>
					</Grid>
				)}
				{details.parcelId && (
					<Grid item>
						<LabeledItem label={t("deliveries.parcelId")}>
							{details.parcelId}
						</LabeledItem>
					</Grid>
				)}
				{details.sender && (
					<Grid item>
						<LabeledItem label={t("deliveries.sender")}>
							{details.sender}
						</LabeledItem>
					</Grid>
				)}
			</Grid>

			<Box my={3} />

			<Grid container spacing={3}>
				<Grid item>
					<LabeledItem label={t("deliveries.condition")}>
						<DeliveryConditionDisplay value={details.condition} />
					</LabeledItem>
				</Grid>
				{details.weight && (
					<Grid item>
						<LabeledItem label={t("deliveries.weight")}>
							<WeightDisplay weight={parseNumberResilient(details.weight)} />
						</LabeledItem>
					</Grid>
				)}
				{(details.storageTimeMax > 0) && (
					<Grid item>
						<LabeledItem label={t("deliveries.storage.storageTimeMax")}>
							<span>{`${details.storageTimeMax} ${t("deliveries.storage.hours")}`}</span>
						</LabeledItem>
					</Grid>
				)}
				{details.orderId && (
					<Grid item>
						<LabeledItem label={t("deliveries.order.orderId")}>
							{details.orderId}
						</LabeledItem>
					</Grid>
				)}
				{details.deliveryNoteId && (
					<Grid item>
						<LabeledItem label={t("deliveries.order.deliveryNoteId")}>
							{details.deliveryNoteId}
						</LabeledItem>
					</Grid>
				)}
				{details.description && (
					<Grid item>
						<LabeledItem label={t("deliveries.order.description")}>
							{details.description}
						</LabeledItem>
					</Grid>
				)}
				{(details.tags && details.tags.length > 0) && (
					<Grid item>
						<LabeledItem label={t("deliveries.tags")}>
							<DeliveryTags label={t("deliveries.tags")} tags={details.tags} readonly />
						</LabeledItem>
					</Grid>
				)}
			</Grid>

			<Box my={3} />

			<Grid container spacing={gs}>
				{details.foreignId && (
					<Grid item>
						<LabeledItem label={t("deliveries.thirdParty.foreignId")}>
							{details.foreignId}
						</LabeledItem>
					</Grid>
				)}
				{details.callbackUrl && (
					<Grid item>
						<LabeledItem label={t("deliveries.thirdParty.callbackUrl")}>
							{details.callbackUrl}
						</LabeledItem>
					</Grid>
				)}
			</Grid>
		</Fragment>
	);
}
