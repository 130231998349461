import {Refresh} from "@mui/icons-material";
import {Alert, Box, Button, CircularProgress, Grid} from "@mui/material";
import {useFlag} from "@variocube/app-ui";
import {createElement, useCallback, useContext, useEffect, useRef, useState} from "react";
import {useLocalization} from "../i18n";
import {gs} from "../theme";
import {CameraRecordingContext} from "./deliveries/CameraRecordingContext";
import {SimpleButton} from "./SimpleButton";
import {StateSnackbar} from "./StateSnackbar";
import {TORCH_ENABLED_KEY, TorchButton} from "./torch-button";

const LAST_SCANNED_IMAGE = "last-scanned-picture";

interface LiveCameraProps {
	onImage: (dataUrl: string) => void;
	lastError?: string;
	allowLastScannedImage?: boolean;
	showSnapButton?: boolean;
	processing?: boolean;
	resolution?: {
		width: number;
		height: number;
	};
}

let localMediaStream: MediaStream | undefined = undefined;

export function LiveCamera(props: LiveCameraProps) {
	const {
		onImage,
		lastError,
		allowLastScannedImage,
		showSnapButton,
		processing,
		resolution,
	} = props;
	const {t} = useLocalization();

	const videoRef = useRef<HTMLVideoElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);

	const {recording, setRecording, analyzeBtnRef} = useContext(CameraRecordingContext);
	const [permissionDenied, setPermissionDenied] = useState(false);
	const [lastScannedImage, setLastScannedImage] = useState<string | undefined>(
		localStorage.getItem(LAST_SCANNED_IMAGE) || undefined,
	);
	const [torchEnabled, setTorchEnabled] = useState(localStorage.getItem(TORCH_ENABLED_KEY) === "true" ?? false);

	const [loading, setLoading, clearLoading] = useFlag(false);

	function enableRecording(torchEnabled: boolean) {
		const video = videoRef.current;
		if (video === null) {
			console.error("Video DOM element not found.");
			return;
		}

		setLoading();

		const videoConfig: MediaTrackConstraints = {
			facingMode: "environment",
			width: {ideal: resolution ? resolution.width : 2560},
			height: {ideal: resolution ? resolution.height : 1920},
		};
		console.info("Starting MediaStream with video config", videoConfig);
		navigator.mediaDevices?.getUserMedia({
			video: videoConfig,
		})
			.then(async (stream) => {
				videoRef.current!.srcObject = stream;
				videoRef.current!.setAttribute("playsinline", "true");
				videoRef.current!.setAttribute("muted", "true");
				videoRef.current!.onloadedmetadata = () => {
					stream?.getVideoTracks().forEach(track => {
						track.applyConstraints({
							advanced: [{torch: torchEnabled}],
						})
							.then(() => console.info("Applied torch constraints"))
							.catch(err => console.error("Failed to apply torch constraints", err));
					});
				};

				try {
					await videoRef.current!.play();
					setRecording(true);
				} catch (err) {
					console.error("Video play error", err);
					setRecording(false);
				}

				localMediaStream = stream;
			})
			.catch(err => {
				console.error("Permission error", err);
				setPermissionDenied(true);
			})
			.finally(() => {
				clearLoading();
			});
	}

	function handleStop() {
		console.info("Stopping steaming service");
		if (videoRef.current !== null) {
			const video = videoRef.current;
			video.pause();
			video.srcObject = null;
			setRecording(false);
		}
		if (localMediaStream !== undefined) {
			for (let track of localMediaStream.getTracks()) {
				track.stop();
				console.info("Stopped MediaStream track", track.id);
			}
		}
	}

	const handleSnap = useCallback(() => {
		if (videoRef.current && canvasRef.current) {
			const video = videoRef.current;
			if (video.readyState === video.HAVE_ENOUGH_DATA) {
				const canvasElement = canvasRef.current;
				canvasElement.hidden = false;
				canvasElement.width = video.videoWidth;
				canvasElement.height = video.videoHeight;
				const canvas = canvasRef.current.getContext("2d");
				if (canvas) {
					canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
					const dataUrl = canvasElement.toDataURL("image/png;base64");
					onImage(dataUrl);
				}
			}
		}
	}, [videoRef, canvasRef, recording]);

	function handleAnalyze() {
		if (videoRef.current && canvasRef.current) {
			const video = videoRef.current;
			if (video.readyState === video.HAVE_ENOUGH_DATA) {
				const canvasElement = canvasRef.current;
				canvasElement.hidden = false;
				canvasElement.width = video.videoWidth;
				canvasElement.height = video.videoHeight;
				const canvas = canvasRef.current.getContext("2d");
				if (canvas) {
					canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
					const dataUrl = canvasElement.toDataURL("image/png;base64");
					try {
						localStorage.setItem(LAST_SCANNED_IMAGE, dataUrl);
					} catch (error) {
						// ignored. this might not work if the image is too large
					}
					onImage(dataUrl);
					console.log("onImage! " + new Date());
				}
			}
		}
	}

	const handleKeyDown = useCallback((event: KeyboardEvent) => {
		if (event.key == "Enter") {
			event.preventDefault();
			handleAnalyze();
		}
	}, [videoRef, canvasRef, recording]);

	const handleLastScannedImage = useCallback(() => {
		if (lastScannedImage) {
			onImage(lastScannedImage);
		}
	}, [lastScannedImage]);

	function handleTorchStateChange(state: boolean) {
		setTorchEnabled(state);
		if (localMediaStream !== undefined) {
			localMediaStream.getVideoTracks().forEach(track => {
				track.applyConstraints({
					advanced: [{torch: state}],
				})
					.then(() => console.info("Applied torch constraints"))
					.catch(err => console.error("Failed to apply torch constraints", err));
			});
		}
	}

	useEffect(() => {
		if (analyzeBtnRef && analyzeBtnRef.current) {
			analyzeBtnRef.current!.onclick = handleAnalyze;
		}
	}, [analyzeBtnRef]);

	useEffect(() => {
		document.addEventListener("keydown", handleKeyDown);
		enableRecording(torchEnabled);

		return () => {
			document.removeEventListener("keydown", handleKeyDown);
			handleStop();
		};
	}, []);

	return (
		<Grid container spacing={gs}>
			<Grid
				item
				lg={8}
				md={12}
				sm={12}
				xs={12}
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{!permissionDenied && (
					<Box
						sx={{
							position: "relative",
						}}
					>
						<TorchButton
							onStateChange={handleTorchStateChange}
							sx={{
								position: "absolute",
								bottom: 10,
								right: 10,
								zIndex: 10,
							}}
						/>
						<video
							ref={videoRef}
							preload="none"
							style={{
								marginLeft: "auto",
								marginRight: "auto",
								display: "block",
								width: "100%",
								cursor: "pointer",
								borderRadius: 3,
								maxWidth: resolution ? resolution.width : 2560,
								maxHeight: resolution ? resolution.height : 1920,
							}}
							onClick={handleSnap}
						/>
						<Box
							sx={{
								display: "none",
							}}
						>
							<canvas ref={canvasRef} />
						</Box>
						{loading && (
							<Box
								sx={theme => ({
									position: "absolute",
									width: "100%",
									height: "100%",
									background: theme.palette.background.paper,
									top: 0,
									left: 0,
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									zIndex: 20,
								})}
							>
								<CircularProgress />
							</Box>
						)}
					</Box>
				)}
				{permissionDenied && (
					<Alert severity="warning">
						{t("deliveries.create.pictureStep.noCamera")}
					</Alert>
				)}
			</Grid>
			{showSnapButton
				&& (
					<Grid item lg={4} md={12} sm={12} xs={12}>
						<SimpleButton
							primary
							label={t("photoInbox.snap.snap")}
							onClick={handleSnap}
							loading={processing}
						/>
					</Grid>
				)}
			<Grid
				item
				lg={4}
				md={12}
				sm={12}
				xs={12}
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{(allowLastScannedImage && lastScannedImage)
					&& (
						<Grid item>
							<Button variant="contained" color="secondary" onClick={handleLastScannedImage}>
								<Refresh />
								{t("deliveries.create.pictureStep.action.lastScan")}
							</Button>
						</Grid>
					)}
			</Grid>

			{lastError
				&& <StateSnackbar message={{severity: "error", text: lastError}} />}
		</Grid>
	);
}
