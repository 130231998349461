import {createElement, useCallback, useEffect, useState} from "react";
import {BreadcrumbItem, Breadcrumbs, ContainerLayout} from "@variocube/app-ui";
import {Alert, Grid, Snackbar, Typography} from "@mui/material";
import {gs} from "../../theme";
import {useLocalization} from "../../i18n";
import {LiveCamera} from "../live-camera";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";
import {useTenant, useTenantId} from "../TenantContextProvider";
import {PhotoInboxProvider} from "../../domain/PhotoInboxProvider";
import {PhotoSummary} from "../../domain/PhotoInbox";

export function SnapPhotos() {
	const {t} = useLocalization();

	const tenantId = useTenantId();
	const tenant = useTenant();

	const [uploads, setUploads] = useState<PhotoSummary[]>([]);
	const [error, setError] = useState<string | undefined>();
	const [uploading, setUploading] = useState<boolean>(false);
	const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
	const [resolution, setResolution] = useState<{width: number, height: number}>();

	useEffect( () => {
		if(tenant && tenant.ocrHeight && tenant.ocrWidth){
			setResolution({width: tenant.ocrWidth, height: tenant.ocrHeight});
		}
	},[]);

	const handleImage = useCallback((dataUrl: string) => {
		PhotoInboxProvider.upload(tenantId, dataUrl)
			.then((result) => {
				setUploads((uploads) => [...uploads, result]);
				setShowSnackbar(true);
			})
			.finally(() => setUploading(false));
	}, [uploads, tenantId]);

	return (
		<ContainerLayout>
			<HelmetTitleWrapper pageTitle={t("photoInbox.snap.title")} />
			<Grid container spacing={gs}>
				<Grid item xs={12}>
					<Breadcrumbs>
						<BreadcrumbRouterLink to={`/${tenantId}/photo-inbox`}>{t("photoInbox.singular")}</BreadcrumbRouterLink>
						<BreadcrumbItem>{t("photoInbox.snap.title")}</BreadcrumbItem>
					</Breadcrumbs>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h1">{t("photoInbox.snap.title")}</Typography>
				</Grid>
				<Grid item xs={12}>
					<LiveCamera onImage={handleImage} lastError={error} showSnapButton processing={uploading} resolution={resolution ? resolution : undefined} />
				</Grid>
			</Grid>
			<Snackbar open={showSnackbar} onClose={() => setShowSnackbar(false)} autoHideDuration={5000} anchorOrigin={{vertical: "bottom", horizontal: "center" }}>
				<Alert severity="success">{t("photoInbox.snap.justSnapped", {count: uploads.length})}</Alert>
			</Snackbar>
		</ContainerLayout>
	);
}
