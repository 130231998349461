import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControlLabel,
	Grid,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import {BreadcrumbItem, Breadcrumbs} from "@variocube/app-ui";
import {ContainerLayout} from "@variocube/app-ui";
import {createElement, Fragment, useMemo, useState} from "react";
import {useAsync} from "react-async-hook";
import {useNavigate} from "react-router";
import {BookmarkRequest, DispatchType} from "../../domain/Bookmark";
import {BookmarksProvider} from "../../domain/BookmarksProvider";
import {Cube} from "../../domain/Cube";
import {Recipient} from "../../domain/Delivery";
import {Site} from "../../domain/Site";
import {SitesProvider} from "../../domain/SitesProvider";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {computeSlug} from "../../utils/textUtils";
import {BoxedPaper} from "../BoxedPaper";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";
import {CubeSelectInput} from "../cubes/CubeSelectInput";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {SiteSelectInput} from "../SiteSelectInput";
import {useTenantId, useTenantUser} from "../TenantContextProvider";
import {DispatchCompaniesList} from "./DispatchCompaniesList";
import {DispatchRecipientsList} from "./DispatchRecipientsList";
import {VisibleForRadios} from "./VisibleForRadios";

interface BookmarkRequestForm extends BookmarkRequest {
}

export const DispatchBookmarkCreate = () => {
	const {t, e} = useLocalization();
	const navigate = useNavigate();
	const tenantId = useTenantId();
	const user = useTenantUser();
	const [form, setForm] = useState<BookmarkRequestForm>({
		id: "",
		type: DispatchType.Other,
		label: "",
		description: "",
		requireConfirmation: false,
		requireInternalRecipient: false,
		requireRecipientAddress: false,
		requireDescription: false,
		requireDispatchDetails: false,
		enableAnonymousDelivery: false,
		recipients: [],
		redirects: [],
		companies: [],
		visibleFor: [],
	});
	const [recipients, setRecipients] = useState<Recipient[]>([]);
	const [redirects, setRedirects] = useState<Recipient[]>([]);
	const [cube, setCube] = useState<Cube | undefined>();
	const [site, setSite] = useState<Site | undefined>();
	const [showNoRecipientDialog, setShowNoRecipientDialog] = useState<boolean>(false);

	useAsync(async () => {
		const sites = await SitesProvider.list(tenantId);
		return setSite(sites.filter(s => s.siteId == user.siteId).pop());
	}, [tenantId, user]);

	async function handleSaveDialog() {
		if (form && recipients.length == 0) {
			setShowNoRecipientDialog(true);
		} else {
			await handleSave();
		}
	}

	async function handleSave() {
		setShowNoRecipientDialog(false);
		try {
			await BookmarksProvider.create(tenantId, {
				...form,
				recipients,
				redirects,
				cubeId: cube ? cube.hostname : undefined,
				siteId: site ? site.siteId : undefined,
			});
			navigate(-1);
		} catch (error) {
			console.error(error);
			alert("Error: Cannot create bookmark.");
		}
	}

	const canSubmit = useMemo(() => {
		let validRecipients = true;
		// TODO validate recipients and redirects
		return Boolean(form && !!form.id && !!form.label && validRecipients);
	}, [form]);

	return (
		<ContainerLayout>
			<HelmetTitleWrapper pageTitle={t("dispatch.singular")} />
			<Grid container spacing={gs}>
				<Grid item xs={12}>
					<Breadcrumbs>
						<BreadcrumbRouterLink to={`/${tenantId}/dispatch`}>{t("dispatch.plural")}</BreadcrumbRouterLink>
						<BreadcrumbItem>{t("create")}</BreadcrumbItem>
					</Breadcrumbs>
				</Grid>
				<Fragment>
					<Grid item xs={12}>
						<Typography variant="h2">{form.label || t("dispatch.singular")}</Typography>
					</Grid>
					<Grid item xs={12}>
						<BoxedPaper>
							<Grid container spacing={gs}>
								<Grid item xs={12}>
									<TextField
										variant="outlined"
										fullWidth
										label={t("dispatch.label")}
										value={form.label}
										onChange={ev => setForm({...form, label: ev.target.value})}
										onBlur={() => setForm({...form, id: computeSlug(form.label)})}
										error={!form.label.trim()}
										helperText={!form.label.trim() ? t("required") : undefined}
									/>
								</Grid>
								<Grid item xs={12}>
									<Grid container spacing={gs}>
										<Grid item sm={4} xs={12}>
											<TextField
												variant="outlined"
												fullWidth
												select
												label={t("dispatch.type")}
												value={form.type}
												onChange={ev => setForm({...form, type: ev.target.value as any})}
											>
												{Object.values(DispatchType).map(t => (
													<MenuItem key={t} value={t}>{e("dispatch.types", t)}</MenuItem>
												))}
											</TextField>
										</Grid>
										<Grid item sm={8} xs={12}>
											<TextField
												variant="outlined"
												fullWidth
												label="Id"
												value={form.id}
												onChange={ev =>
													setForm({...form, id: computeSlug(ev.target.value.toLowerCase())})}
												error={!form.id.trim() || !form.id.match(/^[a-z0-9-]+$/)}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<TextField
										variant="outlined"
										fullWidth
										label={t("dispatch.description")}
										value={form.description}
										onChange={ev => setForm({...form, description: ev.target.value})}
										multiline
										rows={4}
									/>
								</Grid>
								<Grid item xs={6}>
									<SiteSelectInput value={site?.siteId} onChange={setSite} autoSelect={true} />
								</Grid>
								<Grid item xs={6}>
									<CubeSelectInput value={cube} onChange={setCube} allowUndefined />
								</Grid>
								<Grid item xs={12}>
									<VisibleForRadios
										visibleFor={form.visibleFor}
										onChange={(visibleFor) => setForm({...form, visibleFor: visibleFor})}
									/>
								</Grid>
								<Grid item xs={12}>
									<Grid container spacing={1}>
										<Grid item xs={12}>
											<FormControlLabel
												control={
													<Checkbox
														checked={form.requireConfirmation}
														onChange={ev =>
															setForm({...form, requireConfirmation: ev.target.checked})}
													/>
												}
												label={t("dispatch.requireConfirmation")}
											/>
										</Grid>
										<Grid item xs={12}>
											<FormControlLabel
												control={
													<Checkbox
														checked={form.requireInternalRecipient}
														onChange={ev =>
															setForm({
																...form,
																requireInternalRecipient: ev.target.checked,
															})}
													/>
												}
												label={t("dispatch.requireInternalRecipient")}
											/>
										</Grid>
										<Grid item xs={12}>
											<FormControlLabel
												control={
													<Checkbox
														checked={form.requireRecipientAddress}
														onChange={ev =>
															setForm({
																...form,
																requireRecipientAddress: ev.target.checked,
															})}
													/>
												}
												label={t("dispatch.requireRecipientAddress")}
											/>
										</Grid>
										<Grid item xs={12}>
											<FormControlLabel
												control={
													<Checkbox
														checked={form.requireDescription}
														onChange={ev =>
															setForm({...form, requireDescription: ev.target.checked})}
													/>
												}
												label={t("dispatch.requireDescription")}
											/>
										</Grid>
										<Grid item xs={12}>
											<FormControlLabel
												control={
													<Checkbox
														checked={form.requireDispatchDetails}
														onChange={ev =>
															setForm({
																...form,
																requireDispatchDetails: ev.target.checked,
															})}
													/>
												}
												label={t("dispatch.requireDispatchDetails")}
											/>
										</Grid>
										<Grid item xs={12}>
											<FormControlLabel
												control={
													<Checkbox
														checked={form.enableAnonymousDelivery}
														onChange={ev =>
															setForm({
																...form,
																enableAnonymousDelivery: ev.target.checked,
															})}
													/>
												}
												label={t("dispatch.enableAnonymousDelivery")}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<DispatchRecipientsList
										label={t("recipients.plural")}
										description={t("dispatch.recipientsDescription")}
										list={recipients}
										onChange={setRecipients}
									/>
								</Grid>
								<Grid item xs={12}>
									<Box p={2}>
										<Divider />
									</Box>
								</Grid>
								<Grid item xs={12}>
									<DispatchRecipientsList
										label={t("dispatch.redirects")}
										description={t("dispatch.redirectsDescription")}
										list={redirects}
										onChange={setRedirects}
									/>
								</Grid>
								<Grid item xs={12}>
									<Box p={2}>
										<Divider />
									</Box>
								</Grid>
								<Grid item xs={12}>
									<DispatchCompaniesList
										label={t("dispatch.company")}
										description={t("dispatch.companyDescription")}
										list={form.companies}
										onChange={(r) => setForm({...form, companies: r})}
									/>
								</Grid>
								<Grid item xs={12} style={{display: "flex", flexDirection: "row"}}>
									<Button
										variant="contained"
										color="primary"
										onClick={handleSaveDialog}
										disabled={!canSubmit}
									>
										{t("save")}
									</Button>
									<Box mx={1} style={{flexGrow: 1}} />
									<Button variant="outlined" onClick={() => navigate(-1)}>{t("cancel")}</Button>
								</Grid>
								<Dialog open={showNoRecipientDialog} fullWidth maxWidth="sm">
									<DialogTitle>{t("dispatch.noRecipientDialogTitle")}</DialogTitle>
									<DialogContent>{t("dispatch.noRecipientDialogContent")}</DialogContent>
									<DialogActions>
										<Button variant="contained" color="primary" onClick={() => handleSave()}>
											{t("yes")}
										</Button>
										<Button
											variant="contained"
											color="primary"
											onClick={() => setShowNoRecipientDialog(false)}
										>
											{t("no")}
										</Button>
									</DialogActions>
								</Dialog>
							</Grid>
						</BoxedPaper>
					</Grid>
				</Fragment>
			</Grid>
		</ContainerLayout>
	);
};
