import {createElement} from "react";
import {MenuItem, TextField} from "@mui/material";
import {useLocalization} from "../../i18n";
import { AppBoxFeature } from "../../domain/Cube";

interface BoxFeatureSelectInputProps {
    boxFeatures: AppBoxFeature[];
    value: AppBoxFeature | undefined;
    onChange: (value: AppBoxFeature | undefined) => void;
    label: string;
    disabled?: boolean;
}

export function BoxFeatureSelectInput(props: BoxFeatureSelectInputProps) {
    const {t} = useLocalization();


    const handleChange = function(str: AppBoxFeature | undefined) {
        props.onChange(str);
    };

    return (
        <TextField  disabled={props.disabled}   variant="outlined" select  label={props.label} value={props.value ?? ''} fullWidth
                    onChange={(e) => handleChange(e.target.value as AppBoxFeature)}>
            <MenuItem key={'undefined'} value={undefined}>{t('none')}</MenuItem>
            {props.boxFeatures.map((s) =>{
                let val = t(`deliveries.create.recipientStep.featureBoxItem.${s}`);
                return <MenuItem key={s} value={s}>{val}</MenuItem>
            }
            )}
        </TextField>
    );
}
