import {
	Alert,
	Autocomplete,
	Button,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material";
import * as React from "react";
import {createElement, useCallback, useState} from "react";
import {Cube} from "../../domain/Cube";
import {CsvColumnMapping, CsvCubeMapping} from "../../domain/TenantUser";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {CubeNameDisplay} from "../CubeNameDisplay";
import {SimpleCheckBox} from "../SimpleCheckBox";
import {EditCubeBuildingMappingDialog} from "./EditCubeBuildingMappingDialog";
import {HeaderWithHelpKey} from "./HeaderWithHelpKey";

interface TenantSettingsCsvImportProps {
	csvColumnMapping: CsvColumnMapping[];
	csvCubeMapping: CsvCubeMapping[];
	csvIgnoreFirstLine: boolean;
	csvCharset?: string;
	callback?: (
		csvColumnMapping: MappingExt[],
		csvCubeMapping: CsvCubeMapping[],
		ignoreFirstLine: boolean,
		charset: string,
	) => void;
}

export interface MappingExt extends CsvColumnMapping {
	error?: boolean;
}

export function TenantSettingsCsvImportEdit(props: TenantSettingsCsvImportProps) {
	const {t, e} = useLocalization();

	const tEnum = useCallback(
		(column: CsvColumnMapping) => e("settings.csvImport.CSVMappingColEnum", column.columnName),
		[e],
	);

	const [csvColumnMappings, setCsvColumnMappings] = useState<MappingExt[]>(props.csvColumnMapping);
	const [csvCubeMapping, setCsvCvCubeMapping] = useState<CsvCubeMapping[]>(props.csvCubeMapping);
	const [ignoreFirstLine, setIgnoreFirstLine] = useState<boolean>(props.csvIgnoreFirstLine);
	const [charset, setCharset] = useState<string>(props?.csvCharset || "");

	const [editingRow, setEditingRow] = React.useState<number>();

	const [addCubeBuildingMapping, setAddCubeBuildingMapping] = useState<boolean>(false);

	function setValue(index: number, val: any) {
		val = parseFloat(val.substr(0, 2)).toFixed(0);
		if (csvColumnMappings) {
			let clone = [...csvColumnMappings];
			clone[index].columnNumber = isNaN(val) ? null : val;
			setCsvColumnMappings(clone);
			if (props.callback) {
				props.callback(clone, csvCubeMapping, ignoreFirstLine, charset);
			}
		}
		validate();
	}

	function validate() {
		let no = csvColumnMappings.filter((element, index, array) => element.columnNumber).length;
		for (let map of csvColumnMappings) {
			map.error = no > 0 && map.mandatory && !map.columnNumber;
		}
		setCsvColumnMappings([...csvColumnMappings]);
	}

	function addMapping(cube: Cube, building: string) {
		let nextVal = [...csvCubeMapping, {pattern: building, cubeId: cube.hostname}];
		setCsvCvCubeMapping(nextVal);
		setAddCubeBuildingMapping(false);
		if (props.callback) {
			props.callback(csvColumnMappings, nextVal, ignoreFirstLine, charset);
		}
	}

	function editMapping(cube: Cube, building: string) {
		let newVal = {pattern: building, cubeId: cube.hostname};
		let nextVal = [...csvCubeMapping];
		if (editingRow != undefined) {
			nextVal[editingRow] = newVal;
		}
		setCsvCvCubeMapping(nextVal);
		setAddCubeBuildingMapping(false);
		if (props.callback) {
			props.callback(csvColumnMappings, nextVal, ignoreFirstLine, charset);
		}
		setEditingRow(undefined);
	}

	function deleteRow() {
		if (editingRow != undefined && editingRow >= 0) {
			let nextVal = [...csvCubeMapping];
			nextVal.splice(editingRow, 1);
			setCsvCvCubeMapping(nextVal);
			setEditingRow(undefined);
			if (props.callback) {
				props.callback(csvColumnMappings, nextVal, ignoreFirstLine, charset);
			}
		}
	}

	return (
		<Grid container spacing={gs}>
			<Grid item xs={12}>
				<HeaderWithHelpKey
					title={t("settings.csvImport.columnConfig")}
					helpPage="Tenant_Settings"
					helpAnchor="CSV_Import_Column_Config"
					language=""
				/>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={gs}>
					{csvColumnMappings.map((val: MappingExt, index: number) => (
						<Grid item key={index} md={2} sm={3} xs={4}>
							<TextField
								fullWidth
								required={val.mandatory}
								type="number"
								label={tEnum(val)}
								value={val.columnNumber}
								error={val.error}
								variant="outlined"
								inputProps={{
									min: 0,
									max: 99,
									step: "1",
								}}
								onChange={(e) =>
									setValue(index, e.target.value)}
							/>
							{val.error
								&& (
									<Alert severity="warning">
										{tEnum(val) + " " + t("missing")}
									</Alert>
								)}
						</Grid>
					))}
				</Grid>
			</Grid>
			<Grid item xs={12}></Grid>
			<Grid item xs={2}>
				<Autocomplete
					freeSolo
					value={charset}
					onChange={(e: any, value: string | null) => {
						setCharset(value ?? "");
						if (props.callback) {
							props.callback(csvColumnMappings, csvCubeMapping, ignoreFirstLine, value ?? "");
						}
					}}
					options={["UTF-8", "UTF-16", "ISO-8859-1", "US-ASCII"]}
					renderInput={(params) => <TextField {...params} label="CSV Charset" />}
				/>
			</Grid>
			<Grid item xs={2}>
				<SimpleCheckBox
					label={t("settings.csvImport.ignoreFirstLine")}
					checked={ignoreFirstLine}
					onChange={(fL) => {
						setIgnoreFirstLine(fL);
						if (props.callback) {
							props.callback(csvColumnMappings, csvCubeMapping, fL, charset);
						}
					}}
				/>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={gs}>
					<Grid item style={{flexGrow: 1}}>
						<HeaderWithHelpKey
							title={t("settings.csvImport.cubeConfig")}
							helpPage="Tenant_Settings"
							helpAnchor="CSV_Import_Cube_Mapping"
							language=""
						/>
					</Grid>
					<Grid item>
						<Button variant="outlined" onClick={() => setAddCubeBuildingMapping(true)}>
							{t("settings.csvImport.addMapping")}
						</Button>
						<EditCubeBuildingMappingDialog
							open={addCubeBuildingMapping}
							onClose={() => setAddCubeBuildingMapping(false)}
							onConfirm={addMapping}
							building={""}
						/>
					</Grid>
				</Grid>
			</Grid>
			{editingRow != undefined
				&& (
					<EditCubeBuildingMappingDialog
						open={true}
						onClose={() => {
							setEditingRow(undefined);
						}}
						onDelete={deleteRow}
						onConfirm={editMapping}
						building={csvCubeMapping[editingRow].pattern}
					/>
				)}
			<Grid item xs={12}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>{t("cubes.singular")}</TableCell>
							<TableCell>{t("recipients.building")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{csvCubeMapping.map((t, index) => (
							<TableRow
								key={t.pattern}
								hover
								onClick={() => {
									setEditingRow(index);
								}}
							>
								<TableCell>
									<CubeNameDisplay cubeId={t.cubeId} />
								</TableCell>
								<TableCell>
									{t.pattern}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Grid>
		</Grid>
	);
}
