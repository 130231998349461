import {Autocomplete, Checkbox} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {TextField} from "@variocube/app-ui";
import * as React from "react";
import {createElement} from "react";
import {useLocalization} from "../../i18n";

interface DeliveryTagsProps {
	label: string;
	placeholder?: string;
	tags?: string[];
	options?: string[];
	onChange?: (tags: string[]) => void;
	readonly?: boolean;
}

interface AutocompleteOption {
	label: string;
	id: string;
}

function tag2LabelId(tags?: string[]): AutocompleteOption[] {
	const {t} = useLocalization();
	if (!tags || tags.length === 0) {
		return [];
	}
	const reg: RegExp = /^\?\?.+\?\?$/;
	let options = tags.map(tag => {
		const lookup = t("tags." + tag as any);
		const label = (lookup && !reg.test(lookup)) ? lookup : tag;
		return {label: label, id: tag};
	});
	return options.sort((x, y) => x.label.localeCompare(y.label));
}

export function DeliveryTags(props: DeliveryTagsProps) {
	const {t} = useLocalization();
	const options = tag2LabelId(props.options);
	const classes = useStyles();
	return (
		<Autocomplete
			className={classes.dropdown}
			multiple
			freeSolo
			readOnly={props.readonly}
			id="tags-outlined"
			options={options || []}
			value={tag2LabelId(props.tags)}
			filterSelectedOptions
			renderInput={(params) => (
				<TextField
					{...params}
					size="medium"
					label={props.label}
					placeholder={props.readonly ? "" : (props.placeholder || props.label)}
				/>
			)}
			sx={{minWidth: 150}}
			onChange={(event, value) => {
				const ids = value.map((x) => {
					return (x as any)?.id ? (x as any)?.id : x;
				});
				const idUnique = ids.filter((value, index, self) => self.indexOf(value) === index);
				props?.onChange && props?.onChange(idUnique);
			}}
		/>
	);
}

const useStyles = makeStyles(theme => ({
	dropdown: {
		"&.MuiAutocomplete-root .MuiAutocomplete-inputRoot": {
			/* paddingTop: "0px !important",
			paddingBottom: "0px",
			borderStyle: "none !important",*/
		},
	},
}));
